.block-info {
  border: 1px solid red;
  position: relative;
}

.block-info-label {
  font-size: 9px;
  position: absolute;
  top: -8px;
  left: 6px;
  background-color: #fff;
  border: 1px solid red;
}

.block-info-buttons {
  position: fixed;
  width: 200px;
  bottom: 0;
  background-color: white;
  color: black;
  border: 1px solid #000;
  z-index: 10000;
}
