//modal info de celda de tabla
.c-modal-info-cell{
  width: 98%;
  max-width: 31.250rem; //500px
  position: absolute;
  z-index: 999;
  padding: 0.938rem;
  background-color: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, .44);
  border-radius: $border-radius;
  //textos
  //.c-modal-info-cell__text{
  //  margin: 0 0 0.313rem 0;
  //  font-family: $font-base;
  //  font-size: 0.750rem;
  //  font-weight: 700;
  //  line-height: 1.5;
  //  //color: $font-color-dark;
  //  &.blue{
  //    color: $font-color-blue-dark;
  //  }
  //  &.blue--italic{
  //    color: $font-color-blue-dark;
  //    font-style: italic;
  //    font-weight:300;
  //  }
  //  &.warning{
  //    color: $font-color-red-dark;
  //  }
  //  &.medium{
  //    font-weight: 600;
  //  }
  //  &.regular{
  //    margin-top: 0.713rem;
  //    font-weight: 400;
  //  }
  //
  //  //iconos
  //  &::before{
  //    font-family: 'Font Awesome 5 Free';
  //    font-weight: 900;
  //    display: inline-block;
  //    margin-right: 0.438rem;
  //    color: $font-color-blue-dark;
  //  }
  //  &.place{
  //    &::before{
  //      content: '\f041';
  //    }
  //  }
  //  &.comment{
  //    &::before{
  //      content: '\f27a';
  //    }
  //  }
  //  &.technicals{
  //    &::before{
  //      content: '\f0c0';
  //    }
  //  }
  //  &.no-technicals{
  //    display: inline-block;
  //    margin-left: 10px;
  //    &::before{
  //      content: '\f235';
  //    }
  //  }
  //}
  //header modal
  .c-modal-info-cell__header{
    position: relative;
    //media queries
    @include mq('phone-wide'){
      flex-direction: row;
    }
    .c-modal-info-cell__header__left{
      width: 100%;
      //media queries
      @include mq('phone-wide'){
        //width: 55%;
      }
    }
    .c-modal-info-cell__header__right{
      display: flex;
      justify-content: center;
      position: absolute;
      right:0;
      top:0;
      .c-modal-info-cell__square{
        width: auto;
        max-width: 5rem;
        height: 2.188rem;
        line-height: 2.188rem;
        padding: 0 0.625rem;
        margin: 0 0.438rem 0.4rem 0;
        background-color: $bg-white;
        border: $border-width $border-style #CED4DA;
        border-radius: $border-radius;
        font-family: $font-base;
        font-size: 0.875rem;
        font-weight: 400;
        color: $font-color-dark;
        &:last-child{
          margin-right: 0;
        }
        &.user{
          .fa-user-check{
            color: $font-color-blue-dark;
          }
          .fa-user{
            color: $color-drag-item;
          }
        }
      }
    }
  }
  //body modal
  .c-modal-info__cell__body{
    margin-top: 0;
    //caja de cada orden
    .c-modal-info-cell__box{
      border: $border-width $border-style $color-blue-dark; //1.5px
      border-radius: $border-radius;
      padding: 0.4rem 1.8rem 0.4rem 0.4rem; //7px
      margin-bottom:0.4375rem;
      //info orders
      //.c-modal-info-cell__orders{
      //  width: 100%;
      //  display: flex;
      //  justify-content: space-between;
      //  flex-wrap: wrap;
      //  margin: 0 auto 0.413rem auto;
      //  list-style-type: none;
      //  margin-bottom: 0;
      //  padding:0;
      //  &.success{
      //    color: $color-success;
      //  }
      //  .c-modal-info-cell__orders__item{
      //    //padding: 0.313rem 0.438rem;
      //    //color: $font-color-blue-dark;
      //    font-weight: 600;
      //    font-size:0.75rem;
      //    &.blue{
      //      color: $font-color-blue-dark;
      //    }
      //    &.warning{
      //      color: $font-color-red-dark;
      //    }
      //    &.success{
      //      color: $color-success;
      //    }
      //    &.medium{
      //      font-weight: 500;
      //    }
      //  }
      //}
    }// background segun tipo: prevent, program, correct
    //& .success{
    //  border-color: $color-success;
    //}
    //& .program{
    //  background-color: lighten($color-program, 20%);
    //}
    //& .prevent{
    //  background-color: lighten($color-prevent, 20%);
    //}
    //& .corrective {
    //  background-color: lighten($color-corrective, 20%);
    //}
  }

  //arrow top por defecto
  &::before,
  &::after{
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    border-style: solid;
  }
  &::before{
    content:"";
    top: -20px;
    left: 5px;
    z-index:9999;
    border-width: 0 15px 22px 15px;
    border-color: transparent transparent #fff transparent;

  }
  &::after{
    top: -23px;
    left: 5px;
    border-width: 0 16px 24px 16px;
    border-color: transparent transparent rgba(0, 0, 0, 0.08) transparent;
    z-index:9998;
  }
  //arrow top arriba a la derecha
  &.flecha-arriba.flecha-derecha{
    &::before,
    &::after{
      content: '';
      left: auto;
      right: 5px;
    }
  }
  //arrow top abajo a la izquierda
  &.flecha-debajo.flecha-izquierda{
    &::before{
      content: '';
      top: auto;
      bottom: -20px;
      border-width: 22px 15px 0 15px;
      border-color: #fff  transparent transparent  transparent;
    }
    &::after{
      top: auto;
      bottom: -24px;
      border-width: 24px 16px 0 16px;
      border-color: rgba(0, 0, 0, 0.08) transparent transparent transparent;
      z-index:9998;
    }
  }
  arrow top abajo a la derecha
  &.flecha-debajo.flecha-derecha{
    @extend .flecha-debajo.flecha-izquierda;
    &::before,
    &::after{
      content: '';
      left: auto;
      right: 5px;
    }
  }
}







