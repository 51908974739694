//MENÚS NAVEGACIÓN

//navegación por pestañas
.nav-tabs{
    padding-top: 1em;
    padding-bottom: 1em;
    border-bottom: 0!important;
    font-size: $font-size-small;
    //media queries
    @include mq('tablet'){
        font-size: $font-size;
    }
    .nav-item{
        .nav-link{
            width: 100%;
            position: relative;
            font-family: $font-base;
            font-size: 0.875em; //14px
            font-weight: 400;
            text-transform: capitalize;
            color: $font-color-light;
            border: 0;
            background-color: transparent;
            &.active{
                pointer-events: none;
                border: 0;
                font-weight: 600;
                color: $font-color-red;
                &::before{
                    content: '';
                    width: 100%;
                    height: 1px;
                    position: absolute;
                    bottom: 0.15em;
                    left: 0;
                    background-color: $color-red;
                }
            }
            &:hover{
                border: 0;
                color: $color-blue-dark;
            }
        }
    }
}
//nav-tabs en modal
.modal-head{
    .nav-tabs{
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        border-bottom: 0;
    }
}

//Sidebar
//se trae de main-structure.css
/* Sidebar */
.sidebar {
    /*display: inline-flex;
     flex: 0 0 230px;*/
    padding: 0;
    transition: all 300ms linear;
    background-color: #2D3B41;
    &.sidebar--float{
        background: #fff;
    }
}
.sidebar--float{
    background-color: #fff;

}
.sidebar .sidebar-nav {
    width: 230px;
    background-color: transparent;
    transition: all 300ms ease;
}

.sidebar .nav {
    width: 100%;
    flex-direction: column;
    transition: all 300ms ease;
    font-size: 1rem;
}

.sidebar .nav-item {
    width: 100%;
    /*height: 3.250rem;*/
    height: auto;
    position: relative;
    z-index: 99;
    margin: 0;
    transition: all 300ms ease;
    /*border-bottom: 1px solid #D3D3D3;*/
    white-space: nowrap;
}

/*.sidebar .nav-item:first-child{
    border-top: 1px solid #D3D3D3;
}*/

.sidebar .nav-item .fa-angle-up,
.sidebar .nav-item .fa-angle-left {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -0.25rem;
    font-size: inherit;
}

.sidebar .nav-link {
    width: 100%;
    height: 3.250rem;
    display: block;
    position: relative;
    padding: 0 1.571em; /*22px*/
    font-family: 'Roboto', sans-serif;
    font-size: 0.875em; /*14px*/
    font-weight: 400;
    line-height: 3.250rem;
    color: #fff;
    text-decoration: none;
    transition: all 300ms ease;
    /* border-left: 5px solid transparent;*/
    background-color: #2D3B41;
    cursor: pointer;
}

.sidebar .nav-link--border {
    position: relative;
    z-index: 100;
    border-bottom: 2px solid #fff;
}

.sidebar .nav-link:hover,
.sidebar .nav-link.active,
.sidebar .nav-link:hover .fa,
.sidebar .nav-link:hover .fas {
    color: #E1A23F;
}

.sidebar .nav-link:hover,
.sidebar .nav-link.active,
.sidebar .nav-link.active,
.sidebar .nav-link.hover-color {
    background-color: #6C757D;
    color: #E1A23F;
}

.sidebar .nav-link.active {
    text-shadow: 1px 1px #2D3B41;
}

.sidebar .nav-link .fa,
.sidebar .nav-link .fas {
    width: 0.714em; /*10px*/
    height: 1em; /*14px*/
    display: inline-block;
    margin-right: 1.643em; /*23px*/
    font-size: 1em; /*14px*/
    color: #D3D3D3;
}

.sidebar .nav-link.active .fa,
.sidebar .nav-link.active .fas,
.sidebar .nav-link.active .far {
    color: inherit;
}

.sidebar .nav-link .badge {
    display: inline-block;
    position: absolute;
    top: calc(50% - 1px);
    transform: translateY(-50%);
    right: 0.75rem;
    font-size: 0.65rem;
    color: #fff;
    transition: all 300ms ease;
    background-color: #BC212C;
}

.sidebar .nav-title {
    padding: .75rem 1rem;
    font-size: 80%;
    font-weight: 700;
    color: #e4e7ea;
    text-transform: uppercase;
    border-left: 5px solid transparent;
    border-bottom: 1px solid #5C5C5C;
}

.sidebar .nav-dropdown-toggle::before {
    font-family: "Font Awesome 5 Free";
    content: "\f104";
    display: inline-block;
    vertical-align: middle;
    font-weight: 900;
    position: absolute;
    top: .75rem;
    right: 1rem;
    transition: all 300ms ease;
}

.sidebar .nav-dropdown-items {
    max-height: 0;
    padding: 0;
    margin: 0;
    overflow-y: hidden;
    transition: all 300ms ease;
}

.sidebar .nav-dropdown-items .nav-item {
    border-bottom: 0;
}

.sidebar .nav-dropdown-items .nav-link:before {
    content: "";
    display: inline-block;
    margin-right: 0.8rem;
    width: 1.125rem;
}

.sidebar .nav-dropdown.open {
    background: rgba(0, 0, 0, .2);
}

.sidebar .nav-dropdown.open > .nav-dropdown-toggle::before {
    transform: rotate(90deg);
}

.sidebar .nav-dropdown.open > .nav-dropdown-items {
    max-height: 1500px;
    border-top: 1px solid #5C5C5C;
}

.sidebar .nav-divider {
    height: 10px;
}

/*sidebar cuando está dentro de main, es decir, que no es el sidebar de navegación general*/
.main .card .sidebar {
    flex-basis: 100%;
}

.main .card .sidebar .sidebar-nav,
.sidebar-minimized .main .card .sidebar .nav {
    width: 100%;
}

.sidebar-minimized .main .card .sidebar .nav-link {
    padding-left: 0.8rem;
    padding-right: 0.8rem;
}
.sidebar-minimized{
 .dropdown--slide{
     .nav-item--dropdown{
         max-height: 100vh;
         //media queries
         @include mq('tablet'){
             max-height: 66vh;
         }
     }
 }
}

