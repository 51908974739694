//BOTONES

//genéricos
button.btn,
a.btn,
.btn{
    width: auto;
    height: $button-height;
    padding: 0 3.125rem;
    border-width: $border-width;
    border-style: $border-style;
    border-radius: $border-radius;
    font-family: $font-base;
    font-size: 0.875rem;
    line-height: $button-height;
    text-align: center;
    text-transform: capitalize;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    //efecto hover
    &:hover{}
    //botón anchura media
    &.btn--medium{
        max-width: 560px;
    }
    &.btn--large{
        height:$button-height-x;
        min-width: 240px;
        &.with-icon-save{
            position: relative;
            &::before{
                font-family: "Font Awesome 5 Free";
                content: '\f0c7';
                font-weight: 900;
                height: 100%;
                position: absolute;
                top: 2px;
                left: 66px;
                font-size: 12px;
            }
        }
    }
    //botón full
    &.btn--full{
        max-width: 100%;
    }
    //botón rojo
    &.btn--red{
        background-color: $bg-button-red;
        border-color: $bg-button-red;
        color: $font-color-white;
    }
    &.btn--grey{
        background-color: $bg-white;
        border-color: $bg-button-grey;
        color: $bg-button-grey;
    }
    //botón fondo blanco
    &.btn--white{
        background-color: $bg-white;
        border-color: $color-gray-light;
        color: $font-color;
    }
    //deshabilitado - disabled -
    &.btn--disabled{
        pointer-events: none;
        background-color: $color-disabled;
        border-color: #CED4DA;
        color: $font-color-disabled;
        .fas,
        .fa,
        .far{
            display: inline-block;
            margin-right: 0.25rem;
        }
    }
    //botón con spinner load
    &.btn--loader{
        border-color: $bg-button-red;
        background-color: $bg-white;
        color: $font-color-red-dark;
        .fas,
        .fa,
        .far{
            display: inline-block;
            margin-right: 0.25rem;
        }
    }
    &.btn--orders{
        @extend .btn--loader;
        height: 2.2rem;
        font-weight:600;
    }
    &.btn--loading{
       @extend .btn--red;
        .fas,
        .fa,
        .far{
            display: inline-block;
            margin-right: 0.25rem;
        }
    }
    &.btn--loaded{
        @extend .btn--loading;
        pointer-events: none;
    }
    //botón 'crear nuevo'
    &.btn--new{
        //max-width: 131px;
        height: 2.500rem;
        padding: 0 0.5rem;
        line-height: 2.500rem;
        background-color: $bg-white;
        font-size: 0.750rem;
        font-weight: 500;
        color: #576267;
        &::after{
            font-family: 'Font Awesome 5 Free';
            content: '\f0d7';
            font-size: 1rem;
            font-weight: 900;
            position: relative;
            bottom: 1px;
            display: inline-block;
            vertical-align: middle;
            margin-left: 1.2rem;
        }
        &:hover{
            box-shadow: 0 3px 3px rgba(0, 0, 0, .34);

        }
        &:focus{
            box-shadow: 0 3px 6px rgba(0, 0, 0, .34);
        }
    }
    //botón crear nuevo con icono +
    &.btn--new-task{
        background-color: $bg-white;
        border-color: $bg-button-red;
        color: $font-color-red-dark;
        &::before{
            font-family: 'Font Awesome 5 Free';
            content: '\f0fe';
            font-weight: 900;
            display: inline-block;
            margin-right: 0.5rem;
        }
    }
    //botón que abre modal (info fontawesome)
    &.btn--openModal{
        width: 0.875rem;
        max-width: 100%;
        display: inline-block;
        vertical-align: middle;
        padding: 0;
        margin-left: 0.2rem;
        border: 0;
        outline: 0;
        background-color: transparent;
        cursor: pointer;
        .fa,
        .fas,
        .far{
            padding: 0;
            font-size: 1.1em;
        }
    }
    //botón que cierra modal
    &.btn--closeModal{
        @extend .btn--red;
        width: 10.000rem;
        max-width: 100%;
        background-color: $bg-button;
        border-color: $color-dark-yellow;
        color: $color-dark-yellow;
        &::before{
            @include hide();
        }
        &:hover{
            color: $font-color-white;
            background-color: $color-dark-yellow;
        }
    }
    //botones que van juntos: abrir filtros, descargar,...
    &.btn-outline-secondary{
        height: auto;
        padding: 0.375rem 0.75rem; //padding de bootstrap
        line-height: $line-height;
    }
    //botón ellipsis: despliega una lista
    &.btn--points{
        width: auto;
        max-width: 100%;
        height: auto;
        padding: 0.313rem 0.875rem;
        font-size: 1.125rem;
        color: #BC212C;
        background-color: transparent;
        border-radius: $border-radius;
        //media queries
        @include mq('tablet-medium'){
            position: absolute;
            top: 0;
            right: 15px;
        }
        .fa,
        .fas,
        .far{
            font-size: inherit;
            color: inherit;
        }
        &:hover{
            box-shadow: 0 3px 3px  rgba(0, 0, 0, .33);
        }
        &:focus{
            outline: 0;
            box-shadow: 0 3px 6px  rgba(0, 0, 0, .33);
        }
    }
}

.button-form-group {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    //media queries
    @include mq('tablet-medium'){
       margin-top: 2rem;
    }
    &.into-col{
        margin-bottom: 0;
        .btn,
        a.btn,
        button.btn{
            width: 100%;
            padding: 0.375rem;
        }
    }
}

.text-underline{
    text-decoration: underline;
}

//link normal (con subrayado)
a.link-underline,
.link-underline{
    font-size: 0.8rem;
    color: $font-color-red-dark;
    text-decoration: underline;
    border: 0;
    cursor: pointer;
    &:hover{
        text-decoration: none;
    }
    &.disabled{
        color: $font-color-disabled;

    }
    //con icono
    &.ico{
        &::before{
            font-family: 'Font Awesome 5 Free';
            font-weight: 900;
            padding-right: 0.15rem;
        }
        &.ico--edit{
            &::before{
                content: '\f044';
            }
        }
    }
}
.filter-tags__right{
    .link-underline{
        &::before{
            font-family: "Font Awesome 5 Free";
            content: '\f0c7';
            font-weight: 900;
            display: inline-block;
            margin-right: 0.4rem;
        }
    }
}






