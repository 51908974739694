//FILTROS ETIQUETAS
.filter-tags{
    @include flexbox();
    @include justify-content(center);
    @include align-items(center);
    @include flex-wrap(wrap);
    font-size: $font-size-small;
    //media queries
    @include mq('tablet'){
        font-size: $font-size;
    }
    //parte izquierda
    .filter-tags__left{
        width: 100%;
        min-width: 180px;
        @include flexbox();
        @include align-items(center);
        @include flex-wrap(wrap);
        //media queries
        @include mq('tablet'){
            width: calc(100% - 130px);
        }
    }
    //parte derecha
    .filter-tags__right{
        width: 100%;
        margin-top: 1em;
        text-align: left;
        //media queries
        @include mq('tablet'){
            width: 120px;
            margin-top: 0;
            text-align: right;
        }
    }
}
//cada bloque de la derecha: 'label' + lista
.filter-tags__block{
    @extend .filter-tags;
    @include flex-direction(column);
    @include align-items(flex-start);
    @include justify-content(flex-start);
    margin-top: 0.2em;
    margin-right: 1.2em;
    margin-bottom: 0.2em;
    //media queries
    @include mq('tablet'){
        @include flex-direction(row);
        @include align-items(center);
    }
    &:last-child{
        margin-right: 0;
    }
    //texto
    .filter-tags__block__label{
        width: auto;
        margin: 0.2em 1em 0.2em 0;
        font-family: $font-base;
        font-size: 0.7em;
        font-weight: 400;
        text-transform: uppercase;
        text-align: left;
        //media queries
        @include mq('tablet'){
            max-width: 140px;
        }
    }
    //texto
    .filter-tags__block__label2{
        width: auto;
        margin: 0.2em 1em 0.2em 0;
        font-family: $font-base;
        font-size: 0.7em;
        font-weight: 400;
        text-align: left;
        //media queries
        @include mq('tablet'){
            max-width: 140px;
        }
    }
    //listado etiquetas
    .filter-tags__block__tags{
        @include flexbox();
        @include align-items(center);
        @include flex-wrap(wrap);
        margin: 0;
        padding: 0;
        list-style-type: none;
        li{
            @include flexbox();
            @include align-items(center);
            margin: 0.2em 0.5em 0.2em 0;
            background-color: lighten($color-gray-light, 45%);
            border: 1px solid lighten($color-gray-light, 40%);
            border-radius: $border-radius;
            &:last-child{
                margin-right: 0;
            }
            //enlaces dentro de etiqueta
            .tag-item{
                display: inline-block;
                color: $font-color-dark;
                //texto dentro de etiqueta
                &.tag-item--text{
                    @extend  .filter-tags__block__label;
                    width: calc(100% - 15px);
                    min-width: 80px;
                    padding: 0 5px;
                    margin: 0;
                    font-size: 0.66em;
                    text-transform: capitalize;
                    text-align: center;
                }
                //cerrar dentro de etiqueta
                &.tag-item--close{
                    width: 15px;
                    font-family: $font-base;
                    font-size: 0.8em;
                    font-weight: 600;
                    text-align: center;
                    border-left: 1px solid #fff;
                    .fas,
                    .fa,
                    .far{
                        font-size: 0.8em;
                    }
                    &:hover{
                        text-decoration: none;
                        opacity: .9;
                        color: $font-color-red;
                    }
                }
            }

        }
    }
}
//link ver más filtros
a.more-tags,
.more-tags{
    display: inline-block;
    margin-top: 0.1em;
    margin-bottom: 0.1em;
    font-family: $font-base;
    font-size: 0.72em;
    font-weight: 600;
    color: $font-color-dark;
    text-decoration: underline;
    &:hover{
        color: inherit;
        text-decoration: none;
        opacity: .9;
    }
}
