
//MODALES

.list-columns-modal-container{
    //background-color: white;
    border: 0;
    padding: 0;
}

.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 200ms ease-in-out;
    z-index: 999;
}

.ReactModal__Overlay li {
    z-index: 10;
}
/*Hace que al abrir el modal oculte el scroll global de la página*/
/*
.ReactModal__Body--open,
{
    overflow-y: hidden;
    //padding-right: 17px;
    .ReactModal__Overlay {
        opacity: 1;
    }
}
*/
.ReactModal__Overlay--after-open {
    &.ReactModal__Overlay {
        opacity: 1;
    }
}


.ReactModal__Content.top-to-bottom{
    width: 98%;
    min-width: 310px;
    max-width: $max-width-modal;
    height: auto !important;
    max-height: 80%;
    position: fixed;
    top: 0;
    left: 50%;
    transform: translate(-50%, -500%);
    z-index: 99;
    //padding: 2.625em 0;
    background-color: #fff;
    font-size: 0.8rem;
    transition: all 500ms ease;
    overflow-x: hidden;
    //barra scroll
    &.ReactModal__Content--after-open{
        //transform: translateY(0);
        top: 12%;
        transform: translate(-50%);
    }
    .card-title{
        margin-top: 0;
        margin-bottom: 0;
    }

}
.ReactModal__Content.top-to-bottom-client{
    width: 98%;
    min-width: 310px;
    max-width: $max-width-modal;
    height: auto !important;
    max-height: 80%;
    position: fixed;
    top: 0;
    left: 50%;
    transform: translate(-50%, -500%);
    z-index: 99;
    //padding: 2.625em 0;
    background-color: #fff;
    font-size: 0.8rem;
    transition: all 500ms ease;
    //barra scroll
    &.ReactModal__Content--after-open{
        //transform: translateY(0);
        top: 12%;
        transform: translate(-50%);
    }
    .card-title{
        margin-top: 0;
        margin-bottom: 0;
    }

}
.ReactModal__Content{
    width: 98%;
}
.ReactModal__Content.list-columns-modal,
.ReactModal__Content.right-to-left {
    min-width: 310px;
    max-width: $max-width-modal + 10px;
    height: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    transform: translateX(-800px);
    transition: all 500ms ease;
    z-index: 99;
    padding: 2.625em 0;
   // background-color: #fff;
    font-size: 0.8rem;
    &.ReactModal__Content--after-open{
        transform: translateX(0);
    }
    //form
    .filters-block__form{
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding-left: 0;
        padding-right: 0;
        .form-group{
            @include flexbox();
            @include justify-content(center);
        }
    }
    //listado checkboxes
    .checkbox-list{
        width: 98%;
        min-width: 150px;
        max-width: 100%;
        height: calc(100vh - 7.4rem);
        min-height: 300px;
        @include flexbox();
        @include flex-wrap(wrap);
        padding-left: 0;
        padding-right: 0;
        list-style-type: none;
        //media queries
        @include mq('tablet-medium'){
           @include flex-direction(column);
        }
        li{
            width: 100%;
            margin-bottom: 0.4rem;
        }
    }
    // pisar ancho input fecha reducir espacio para ecvitar scroll
    .form-group.DateInputField{
        width: 172px !important;
    }
    .table-edit .table tbody tr.editing td{
        padding-left: 0.3rem;
        padding-right: 0.3rem;
    }
    .remove-add{
        margin-left: 0;
    }
}

//MODAL
.modal-push{
    width: 100%;
    min-width: 310px;
    max-width: $max-width-modal + 10px;
    height: 100vh;
    //height: auto;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    padding: 0 0 2.625em 0;
    background-color: #fff;
    //transition: all 0.500ms linear;

    .modal-head{
        padding: 0.6em 1.2em;
        //border-bottom: 1px solid $border-filter-color;
        .close-modal{
            width: 100%;
            height: 100%;
            display: block;
            font-size: 1.65em;
            color: $font-color-dark;
            .fas,
            .fa,
            .far{
                font-size: inherit;
                color: inherit;
            }
            &:hover{
                opacity: .9;
            }
        }
    }
    .form-radio-label{
        max-height: 20px;
        font-size: 0.65rem;
        overflow-y: unset!important;
    }
    .modal-body{
        @extend .modal-head;
        height: 100vh;
        max-height: calc(100vh - 150px);
        overflow-y: auto;
        padding: 0.6em;
        //padding-top: 1.6em;
        //padding-bottom: 1.6em;
        border-bottom: 0;
        &.full-height{
            height: 98%;
            max-height: 100%;
        }
    }
    .modal-body-filters{
        @extend .modal-head;
        height: 100%;
        max-height: calc(100vh - 150px);
        overflow-y: visible;
        padding-top: 1.6em;
        padding-bottom: 1.6em;
        border-bottom: 0;
      &.full-height{
            height: 98%;
            max-height: 100%;
        }
    }
    .modal-footer{
        @extend .modal-head;
        width: 100%;
        height: 180px;
        display: block;
        margin-top: 10px;
        margin-bottom: 10px;
        padding-top: 30px;
        border-bottom: 0;
    }
    //TÍTULO Y CERRAR MODAL
    .modal-title,
    .close{
        height: 2.375rem; /*38px*/
    }
    .modal-title{
        display: flex;
        align-items: center;
        padding: 0;
        background-color: $bg-white;
        img{
            margin-right: 0.875em;
        }
        h2,
        p{
            margin: 0;
            font-family: $font-secondary;
            font-size: 1.250em; /*20px*/
            font-weight: 300;
            text-transform: capitalize;
            letter-spacing: 1px;
            color: $font-color-dark;
            span{
                font-weight: 600;
            }
        }
    }
    .close{
        width: 2.500rem;
        float: none;
        padding: 0;
        margin-left: 0.5em;
        font-size: 1rem; /*16x*/
        font-weight: 400;
        text-shadow: none;
        color: #D0D7DB;
        border: 1px solid #D0D7DB;
        border-radius: 4px;
        opacity: 1;
        background-color: #fff;
        transition: all 0.2s ease;
        cursor: pointer;
        &:hover{
            span{
                transform: scale(1.1);
                color: $font-color-dark;
            }
        }
        &:focus{
            box-shadow: 0 0 0 0.15rem rgba(0, 0, 0, 0.25);
            outline: 0;
        }
        span{
            width: 100%;
            height: 100%;
            display: block;
            font-size: inherit;
            line-height: 2.375rem;
        }
        //cuando tiene otro botón pegado
        &.fusion{
            margin-left: 0;
            border-radius: 0 $border-radius $border-radius 0;
        }
    }
    .edit-modal{
        @extend .close;
        border-right: 0;
        border-radius: $border-radius 0 0 $border-radius;
    }
    //texto bajo título
    .modal-title-info{
        margin-top: 0.300em;
        margin-bottom: 0;
        font-family: $font-base;
        font-size: 0.750em; //12px
        font-weight: 400;
        color: $font-color-light;
    }
}

//MODAL que sale de derecha
.ReactModal__Content{
    &.right-to-left{
        left: auto;
        right: 0;
        transform: translateX(800px);
        transition: all 500ms ease;
        &.ReactModal__Content--after-open{
            transform: translateX(0);
        }
        .modal-push{
            .modal-body{
                padding-top: 0.5em;
                padding-bottom: 1em;
            }
        }
    }
    //cuando es central
    &.center{
        &.ReactModal__Content--after-open{
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .modal-push{
            width: 100%;
            //max-width: 765px;
            height: auto;
            max-height: 450px;
            position: static;
            padding: 0;
            background-color: #fff;
            .form-group{
                margin-bottom: 0.2rem;
            }
            //head
            .modal-head{
                background-color: #f6f6f6;
                border-bottom: 0;
            }
            .modal-title{
                background-color: transparent;
                font-family: $font-base;
                font-size: 0.875rem;
                font-weight: 500;
                color: $color-title;
                p{
                    font-family: inherit;
                    font-size: inherit;
                    font-weight: inherit;
                    span{
                        font-weight: 600;
                    }
                }
                &::before{
                    font-family: "Font Awesome 5 Free";
                    content: '\f12a';
                    font-weight: 900;
                    opacity: .6;
                    display: inline-block;
                    margin-right: 0.813rem;
                }
            }
          //body
          .modal-body{
            padding-top: 0.5rem;
            padding-bottom: 0;
            background-color: #fff;
          }
          .modal-body-filters{
          padding-top: 0.5rem;
          padding-bottom: 0;
          background-color: #fff;
          }
          //texto normal
            .modal-center-text{
                font-family: $font-base;
                font-size: 0.8rem;
                font-weight: 400;
                text-align: left;
                line-height: $line-height;
                color: $font-color-xdark;
            }
            //texto pequeño
            small{
                &.js-error,
                &.help-block{
                    color: $font-color-light;
                }

            }
            //lista checkboxes
            .checkbox-list{
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                margin-top: 0.3rem;
                padding: 0;
                list-style-type: none;
                .drag-li{
                    @extend .modal-center-text;
                    margin: 0 0.938rem 0.2rem 0;
                    color: $font-color-blue-dark;
                }
            }
            //footer
            .modal-footer{
                height: auto;
                margin: 0;
                padding-top: 0;
                background-color: $bg-white;
                border-top: 0;
                .btn{
                    margin-right: 0.938rem;
                    &:last-child{
                        margin-right: 0;
                    }
                }
            }
        }
    }
}
.modal-center-text{
    margin-bottom: 0;
}


//cuando abren
/*.ReactModal__Body--open{
    .ReactModal__Content{
        transform: translateX(0);
        transition: all 500ms ease;
    }
}*/

//navegación por pestañas en  modal
.nav-tabs--modal{
    width: 100%;
    @include flexbox();
    @include align-items(center);
    margin-bottom: 0;
    font-size: $font-size;
    .nav-item{
        margin-bottom: 0;
        margin-left: 15px;
        margin-right: 15px;
        padding-bottom: 0.5em;
        .nav-link{
            padding-left: 0;
            padding-right: 0;
            font-family: $font-base;
            font-size: 0.875em;
            font-weight: 400;
            text-transform: capitalize;
            color: $font-color-light;
            &.active{
                font-weight: 500;
                color: $font-color-red;
            }
        }
    }
}
//contenido de cada pestaña
.tab-content--modal{
    margin-top: 0.8em;
}
//contenido que sale ya pintado y se puede editar
.editable{
    font-size: $font-size;
    //texto de última modificación
    .last-modified{
        margin-top: -0.8rem;
        font-family: $font-base;
        font-size: 0.750rem;
        font-weight: 400;
        text-align: left;
        line-height: $line-height;
        color: $font-color-light;
    }
    //borde gris claro en grupo de divs
    .block{
        margin-top: 0.5em;
        margin-bottom: 0.5em;
        &:first-child{
            margin-top: 0;
        }
        &.block--border{
            width: 100%;
            max-width: 650px;
            //margin-bottom: calc(1rem + #{$height-message});
            margin-bottom: $item-mb;
            padding: 1em;
            border: $border-width $border-style $border-filter-color;
            border-radius: $border-radius;
            .group{
                margin: 0;
            }
            .control{
                font-size: 0.8rem;
            }
        }
    }
    //grupo de dos div: label-
    .group{
        margin: 0.25em 0;
    }
    //label
    .control{
        margin-bottom: 0.25em;
        font-family: $font-base;
        font-size: 0.875rem;
        font-weight: 400;
        text-align: left;
        line-height: $line-height;
        color: $font-color-light;
    }
    //inputs
    .div-control{
        min-height: 2rem;
        padding: 0;
        border: 0;
        font-family: $font-base;
        font-size: 0.875em;
        font-weight: 500;
        color: $font-color-xdark;
        //cuando es doble: nombre y teléfono
        &.double{
            .div-control__name{
                margin-bottom: 0.25rem;
                font-family: $font-base;
                font-weight: 400;
                text-transform: capitalize;
                color: $font-color-dark;
                &::before{
                    font-family: "Font Awesome 5 Free";
                    content: '\f007';
                    font-weight: 900;
                    display: inline-block;
                    margin-right: 0.4rem;
                    color: $color-green-light;
                }
            }
            .div-control__phone{
                @extend .div-control__name;
                margin-bottom: 0;
                text-transform: none;
                &::before{
                    content: '\f095';
                }
            }
        }
        //color rojo
        &.red{
            color: $font-color-red;
        }
        //cuando es la ubicación
        &.ubicacion{
            &::before{
                font-family: "Font Awesome 5 Free";
                content: '\f3c5';
                font-weight: 900;
                display: inline-block;
                margin-right: 0.4rem;
                color: $color-blue-dark;
            }
        }
        //cuando es la zona
        &.zona{
            &::before{
                font-family: "Font Awesome 5 Free";
                content: '\f5a0';
                font-weight: 900;
                display: inline-block;
                margin-right: 0.4rem;
                color: $color-blue-dark;
            }
        }
    }
    //textos de aviso
    .notice{
        margin-top: 0.5em;
        margin-bottom: 0.5em;
        font-family: $font-base;
        font-size: 0.875em;
        font-weight: 400;
        text-align: left;
        line-height: $line-height;
        color: $font-color-xdark;
        &::before{
            font-family: "Font Awesome 5 Free";
            content: '';
            font-weight: 900;
            display: inline-block;
            margin-right: 0.4rem;
            color: $color-green-light;
        }
        &.exclamation{
            &::before{
                content: '\f06a';
            }
        }
        &.comment{
            &::before{
                content: '\f27a';
            }
        }
    }
    //imágenes adjuntas
    .attach-images{
        @include flexbox();
        @include flex-wrap(wrap);
        .attach-images__item{
            width: auto;
            min-width: 80px;
            max-width: 164px;
            height: auto;
            min-height: 80px;
            max-height: 104px;
            margin: 0.15rem;
            img{
                @include img();
            }
        }
    }
}

//Modal genérico 'más información'
.modal-info{
    background-color: rgba(255, 255, 255, .95);
    .modal-dialog{
        width: 96%;
        max-width: 770px;
        .modal-content{
            border: $border-width $border-style $bg-title;
            border-radius: 0;
            font-size: $font-size-small;
            //media queries
            @include mq('tablet'){
                font-size: $font-size;
            }
            .modal-header{
                background-color: $bg-title;
                .modal-header__title{
                    margin: 0;
                    padding: 0;
                    font-family: $font-base;
                    font-size: 0.875em;
                    font-weight: 500;
                    text-align: left;
                    line-height: $line-height;
                    color: $color-title;
                    &.ico{
                        &::before{
                            font-family: 'Font Awesome 5 Free';
                            font-weight: 900;
                            content: '';
                            display: inline-block;
                            margin-right: 0.5rem;
                            font-size: inherit;
                            line-height: inherit;
                            color: inherit;
                        }
                        //info
                        &.ico--info{
                            &::before{
                                content: '\f129';
                            }
                        }
                    }
                }
            }
            .modal-body{
                padding: 2em;
               .modal-info__list{
                   .modal-info__list__title{
                       margin: 1em 0 0.5em 0;
                       font-family: $font-base;
                       font-size: 0.875rem;
                       font-weight: 400;
                       line-height: $line-height;
                       color: $font-color-xdark;
                       &:first-child{
                           margin-top: 0;
                       }
                   }
                   .modal-info__list__item{
                        @extend .modal-info__list__title;
                        position: relative;
                        margin-top: 0;
                        margin-bottom: 0.25em;
                        padding-left: 3em;
                       &::before{
                           font-family: 'Font Awesome 5 Free';
                           content: '\f111';
                           font-weight: 900;
                           color: #DAA53D;
                           position: absolute;
                           top: 0;
                           left: 1em;
                           display: inline-block;
                           margin-right: 0.5rem;
                       }
                   }
               }
            }
            .modal-footer{
                border-top: 0;
            }
        }
    }
}
//cuando es clickable
.text-click{
    cursor: pointer;
    &:hover{
        text-decoration: underline;
    }
}
////modal info de celda de tabla
//.modal-info-cell{
//    width: 98%;
//    max-width: 31.250rem; //500px
//    position: absolute;
//    z-index: 999;
//    padding: 0.938rem;
//    background-color: #DEE8EE;
//    box-shadow: 0 3px 6px rgba(0, 0, 0, .44);
//    border-radius: $border-radius;
//    //textos
//    .modal-info-cell__text{
//        margin: 0 0 0.313rem 0;
//        font-family: $font-base;
//        font-size: 0.750rem;
//        font-weight: 700;
//        line-height: 1.5;
//        color: $font-color-dark;
//        &.blue{
//            color: $font-color-blue-dark;
//        }
//        &.warning{
//            color: $font-color-red-dark;
//        }
//        &.medium{
//            font-weight: 500;
//        }
//        &.regular{
//            margin-top: 0.713rem;
//            font-weight: 400;
//        }
//
//        //iconos
//        &::before{
//            font-family: 'Font Awesome 5 Free';
//            font-weight: 900;
//            display: inline-block;
//            margin-right: 0.438rem;
//            color: $font-color-blue-dark;
//        }
//        &.place{
//            &::before{
//                content: '\f041';
//            }
//        }
//        &.comment{
//            &::before{
//                content: '\f27a';
//            }
//        }
//        &.technicals{
//            &::before{
//                content: '\f0c0';
//            }
//        }
//        &.no-technicals{
//            &::before{
//                content: '\f235';
//            }
//        }
//    }
//    //header modal
//    .modal-info-cell__header{
//        display: flex;
//        justify-content: space-between;
//        flex-direction: column;
//        //media queries
//        @include mq('phone-wide'){
//            flex-direction: row;
//        }
//        .modal-info-cell__header__left{
//            width: 100%;
//            //media queries
//            @include mq('phone-wide'){
//                width: 55%;
//            }
//        }
//        .modal-info-cell__header__right{
//            display: flex;
//            justify-content: center;
//            .modal-info-cell__square{
//                width: auto;
//                max-width: 5rem;
//                height: 2.188rem;
//                line-height: 2.188rem;
//                padding: 0 0.625rem;
//                margin: 0 0.438rem 0.4rem 0;
//                background-color: $bg-white;
//                border: $border-width $border-style #CED4DA;
//                border-radius: $border-radius;
//                font-family: $font-base;
//                font-size: 0.875rem;
//                font-weight: 400;
//                color: $font-color-dark;
//                &:last-child{
//                    margin-right: 0;
//                }
//                &.user{
//                    .fa-user-check{
//                        color: $font-color-blue-dark;
//                    }
//                    .fa-user{
//                        color: $color-drag-item;
//                    }
//                }
//            }
//        }
//    }
//    //body modal
//    .modal-info__cell__body{
//        margin-top: 0;
//        //info orders
//        .modal-info-cell__orders{
//            width: 100%;
//            display: flex;
//            justify-content: space-between;
//            flex-wrap: wrap;
//            margin: 0 auto 0.413rem auto;
//            padding: 0;
//            list-style-type: none;
//            background-color: $bg-white;
//            border: $border-width $border-style #CED4DA;
//            border-radius: $border-radius;
//            .modal-info-cell__orders__item{
//                padding: 0.313rem 0.438rem;
//                &.blue{
//                    color: $font-color-blue-dark;
//                }
//                &.warning{
//                    color: $font-color-red-dark;
//                }
//                &.medium{
//                    font-weight: 500;
//                }
//            }
//        }
//    }
//    //arrow top por defecto
//    &::before,
//    &::after{
//        content: '';
//        width: 0;
//        height: 0;
//        position: absolute;
//        border-style: solid;
//    }
//    &::before{
//        content:"";
//        top: -20px;
//        left: 5px;
//        z-index:9999;
//        border-width: 0 15px 22px 15px;
//        border-color: transparent transparent #DEE8EE transparent;
//
//    }
//    &::after{
//        top: -23px;
//        left: 5px;
//        border-width: 0 16px 24px 16px;
//        border-color: transparent transparent rgba(0, 0, 0, 0.08) transparent;
//        z-index:9998;
//    }
//    //arrow top arriba a la derecha
//    &.flecha-arriba.flecha-derecha{
//        &::before,
//        &::after{
//            content: '';
//            left: auto;
//            right: 5px;
//        }
//    }
//    //arrow top abajo a la izquierda
//    &.flecha-debajo.flecha-izquierda{
//        &::before{
//            content: '';
//            top: auto;
//            bottom: -20px;
//            border-width: 22px 15px 0 15px;
//            border-color: #DEE8EE transparent transparent  transparent;
//        }
//        &::after{
//            top: auto;
//            bottom: -24px;
//            border-width: 24px 16px 0 16px;
//            border-color: rgba(0, 0, 0, 0.08) transparent transparent transparent;
//            z-index:9998;
//        }
//    }
//    //arrow top abajo a la derecha
//    &.flecha-debajo.flecha-derecha{
//        @extend .flecha-debajo.flecha-izquierda;
//        &::before,
//        &::after{
//            content: '';
//            left: auto;
//            right: 5px;
//        }
//    }
//}
//
//
//
//
//
//
//


