//Bloque de filtros compartidos

.c-filters-shared{
    //bloques - columnas
    &__col{
        margin-bottom: 2rem;
        &:last-child{
            margin-bottom: 0;
        }
        //título de columna
        &__title{
            margin: 0 0 0.8rem 0;
            padding: 0;
            font-family: $font-base;
            font-size: 0.8rem;
            font-weight: 600;
            line-height: $line-height;
            text-align: left;
            text-transform: capitalize;
            color: $font-color-xdark;
        }
    }
    //listas
    &-list{
        padding: 0;
        list-style-type: none;
        //cada li de lista
        &__item{
            margin-bottom: 0.4rem;
            font-family: $font-base;
            font-size: 0.8rem;
            font-weight: 400;
            line-height: $line-height;
            text-align: left;
            text-transform: none;
            text-decoration: none;
            color: $font-color-dark;
            &:last-child{
                margin-bottom: 0;
            }
            //span con onclick (enlace)
            &-link{
                display: inline-block;
                vertical-align: middle;
                font-family: inherit;
                font-size: inherit;
                font-weight: inherit;
                letter-spacing: inherit;
                cursor: pointer;
                &:hover{
                    text-decoration: underline;
                }
                &:focus{
                    outline: $focusDark;
                }
            }
            //icono eliminar
            .remove{
                display: inline-block;
                vertical-align: middle;
                margin-left: 0.6rem;
                margin-right: 0;
                font-size: 0.9rem;
                background-color: transparent;
                color: $font-color-red;
                .fas,
                .fa,
                .far{
                    position: static;
                    transform: none;
                    font-size: inherit;
                    color: inherit;
                }
                &:hover{
                    color: $font-color-dark;
                }
            }
        }
    }
}

//para pisar estilos que se aplican desde etiquetas o anidados
h1.c-filters-shared__col-title,
.modal-push [class*="col-title"]{
    @extend .c-filters-shared__col__title;
}
