//lista desplegabla al hacer click en botón (crear nuevo)
.dropdown-sublist{
    width: auto;
    min-width: 150px;
    position: absolute;
    top: 2.2rem;
    right: 0;
    z-index: 99;
    padding: 0.438em 0;
    background-color: $bg-white;
    box-shadow: $box-shadow;
    font-size: $font-size-xsmall;
    //media queries
    @include mq('tablet'){
        font-size: $font-size;
    }
    .dropdown-sublist__head{
        margin-bottom: 0.2em;
        padding: 0.500em 1.286em;
        font-family: $font-base;
        font-size: 0.875em;
        font-weight: 400;
        text-transform: capitalize;
        text-align: left;
        color: $font-color-red-dark;
        border-bottom: 1px solid $border-list-color;
    }
    .dropdown-sublist__item{
        @extend  .dropdown-sublist__head;
        padding: 0;
        margin-bottom: 0.1em;
        color: $font-color-light;
        border-bottom: 0;
        a{
          width: 100%;
          height: 100%;
          display: block;
          padding: 0.500em 2.800em;
          font-family: inherit;
          color: inherit;
          transition: all 0.2s ease;
          &.item-active,
          &:hover{
            color: $font-color-dark;
            background-color: $bg-item-active;
            text-decoration: none;
            cursor: pointer;
          }
        }
    }
}

//lista desplegable cuando es tabla (slot)
.table-edit{
    .table-responsive{
        .table{
            tbody{
                &.border-end{
                    tr{
                        &.editing{
                            position: relative;
                            td{
                                .dropdown-sublist{
                                    &.table{
                                        table-layout: fixed;
                                        max-width: 1250px;
                                        top: auto;
                                        right: auto;
                                        left: 50%;
                                        transform: translateX(-50%);
                                        //media queries
                                        @include mq('tablet'){
                                            table-layout: auto;
                                        }
                                        tr{
                                            td{
                                                font-size: 0.8rem;
                                                color: $font-color;
                                                input[type="checkbox"]{
                                                    -webkit-appearance: none;
                                                    -moz-appearance: none;
                                                    appearance: none;
                                                    width: 16px;
                                                    height: 16px;
                                                    position: relative;
                                                    border: 1px solid #707070;
                                                    &:checked{
                                                        &::after{
                                                            font-family: "Font Awesome 5 Free";
                                                            content: '\f00c';
                                                            font-size: 11px;
                                                            font-weight: 900;
                                                            color: $font-color-blue-dark;
                                                            position: absolute;
                                                            top: 50%;
                                                            left: 50%;
                                                            transform: translate(-50%, -50%);
                                                        }
                                                        &:focus{
                                                            box-shadow: $box-shadow;
                                                        }
                                                    }
                                                }
                                            }
                                            td[scope="row"]{
                                                + td{
                                                    font-weight: 600;
                                                    color: $font-color-blue-dark;
                                                }
                                            }
                                            //seleccionada
                                            &.tr-selected{
                                                background-color: #eee;
                                            }
                                            //error
                                            &.tr-error{
                                                td[scope="row"]{
                                                    + td{
                                                        color: $color-red-dark;
                                                    }
                                                }
                                                td{
                                                    &:last-child,
                                                    &:nth-child(6){
                                                        color: $color-red-dark;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.table .Select-menu-outer {
  min-width:280px;
    z-index: 9999;
}
.table .Select {
  min-width:160px;
}

.table-edit .table tbody tr.editing td {
  padding-left: 0.4rem;
  padding-right: 0.4rem;
}
