.modal-push{
    //columnas y paddings en modales
    *[class="row"]{
        margin-left: -8px;
        margin-right: -8px;
    }
    *[class*="col-"]{
        padding-left: 8px;
        padding-right: 8px;
    }
}
.web-body{
    .form-group{
        margin-bottom: $item-mb;
        .form-checkform-check-inline{
            .Select2Component{
                margin-bottom: 0;
            }
        }
    }
}