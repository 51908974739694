
.drop-work-unit {
  td {
    border: 1px solid black;
    // height:30px;
    // width:30px;
  }

  td:first-child {
    border-left: 0;
  }

  td:last-child {
    border-right: 0;
  }

  th:nth-child(1) {
    //width: 100px;
  }

  td.week {
    border-left: 1px solid black;
    border-right: none;
  }


}

.drop-work-unit.mes {

}

.drop-work-unit.semana {
  td {
    //width:100px;
  }
}

.drop-work-unit.twodias {
  td {
    //width:360px;
  }
}

//TABLA: planificación cargas trabajo
.table-second {
  //max-height: 700px;
  .table {
    //min-width: 1260px;
    width: auto;
    box-shadow: $box-shadow;

    thead {
      th {
        width: auto;
        //max-width: $cell-width;
        padding: 0 0.571rem;
        border-bottom: 1px solid $color-xdark;
        border-right: 1px solid $color-xdark;

        &:last-child {
          border-right: 0;
        }
      }
    }

    .th-top {
      margin-bottom: 0.857em; //12px
      font-family: $font-secondary;
      font-size: 0.8rem;
      color: $font-color-xdark;
      text-align: left;
    }

    .th-cliente {
      width: 205.2px;
    }

    .th-top-cliente {
      width: 209px;
    }

    .display-flex-calendar {
      display: flex;
      justify-content: space-between;
    }

    .th-bottom {
      display: flex;
      justify-content: space-between;
      padding: 0;
      margin: 0;
      list-style-type: none;
      //cuando son números de día de la semana
      &.num {

        width: 100%;
        //max-width: $cell-width;
        margin: 0 auto;

        .fa-angle-left {
          display: unset;
          visibility: visible;
        }

        .th-bottom-client {
          width: 36.9px !important;
          display: flex;
        }

        .th-bottom__item {
          //width: 80px;
          border-right: 1px solid $color-xdark;
          text-align: center;
          margin: 0 auto;

          &:last-child {
            border-right: 0;
          }


          span {
            display: inline-block;
          }
        }
      }

      //cuando es texto
      &.text {
        .th-bottom__item {
          max-width: 110px;
        }
      }
    }

    .th-bottom__item {
      //min-width: 110px;
      font-family: $font-secondary;
      font-size: 0.688rem; //11px
      color: $font-color-xdark;
      max-width: 100% !important;

      .day {
        margin-left: 2px;
        font-size: 0.75rem;
        color: $color-border;
        text-transform: uppercase;
      }
    }

    .day {
      @extend .th-bottom__item;

      b {
        margin-right: 0.2rem;
      }
    }

    tbody {
      th {
        padding: 0.25em 0.5em;
        line-height: 1.3;
        border-bottom: 2px solid $color-drag-item;

        .shop-num {
          display: block;
        }

        .th-bottom {
          .th-bottom__item {
            font-family: $font-base;
            color: $font-color-medium;
          }

        }

        //tabla con ítems que se arrastran
        .table-sublist {
          display: flex;
          justify-content: flex-end;
          padding: 0;
          list-style-type: none;

          li {
            width: 12px;
            height: 33px;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            margin-right: 3px;
            background-color: $color-drag-item;

            &:last-child {
              margin-right: 0;
            }

            &.no-active {
              opacity: 0.5;
              pointer-events: none;
            }

            a {
              text-decoration: none;
              display: block;
              margin-bottom: 0.3em;
              font-size: 0.75em;
              font-weight: 800;
              color: $font-color-dark;
              //h
              .small {
                font-size: 0.65em;
              }
            }
          }
        }
      }

      //celdas no th
      td {
        width: auto;
        //max-width: 80px;
        height: $cell-height;
        padding: 0;
        vertical-align: middle;
        border-left: 1px solid $color-border;
        border-bottom: 1px solid $color-border;

        .th-bottom {
          &.num {
            height: 100%;
            overflow: hidden;

            .th-bottom__item {
              height: 100%;
              border-right: 1px solid $color-border;

              &:last-child {
                border-right: 0;
              }

              .left,
              .right {
                width: calc(50% - 2px);
                height: 100%;
                position: relative;
                display: inline-block;
                margin: 1px;
              }

              .left {
                &::after {
                  content: '';
                  width: 1px;
                  height: 15px;
                  position: absolute;
                  right: 0;
                  bottom: 0;
                  background-color: $color-border;
                }
              }

              &.no-work {
                background-color: lighten($color-border, 5%);
                pointer-events: none;
              }
            }
          }
        }
      }

      th {
        &.th-bg {
          max-width: 70px;
          padding-top: 0.8em;
          padding-bottom: 0.8em;
          font-family: $font-base;
          font-size: 1em; //14px
          font-weight: 400;
          color: #fff;
          background-color: $color-gray-dark;
        }
      }
    }
  }

  //con algunos cambios
  &.second {
    .table {
      th {
        .th-bottom {
          &.text {
            .th-bottom__item {
              font-size: 0.88em;
            }
          }

        }
      }

      tbody {
        tr {
          &.no-work {
            td {
              position: relative;

              &::before {
                content: '';
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
                background-color: rgba(0, 0, 0, .5);
                pointer-events: none;
              }
            }
          }
        }

        th {
          vertical-align: middle;
        }
      }
    }
  }
}

//TABLA: horas planificadas
.table-third {
  @extend .table-second;

  .table {
    height: 155px;


    tbody {
      th {
        width: auto;
        //max-width: $cell-width;
        min-height: 135px;
        position: relative;
        padding: 0.857em 0.571em;
        background-color: $color-gray-dark;
        border-top: 0;
        border-bottom: 0;

        .th-top,
        .th-bottom {
          font-family: $font-base;
          font-size: 0.8rem;
          font-weight: 400;
          text-align: left;
          color: $font-color-white;

          span {
            font-size: inherit;
            color: inherit;
          }
        }


        .th-bottom {
          height: $height-bottom;
          line-height: $height-bottom;
          position: absolute;
          right: 0.857em;
          bottom: 0;
          text-align: right;

        }

      }

      td {
        height: 100%;
        padding: 0;

        .th-bottom {
          line-height: $height-bottom;
          position: relative;
          text-align: right;
        }
      }
    }
  }
}

.contenedor-client {
  height: 117px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.hour-client {
  line-height: 16px;
}

.calc-hour {
  justify-content: center !important;
  margin: 0 auto !important;
  width: 50%;
}

.calc-hour1 {
  @extend .calc-hour;
  background: #A2BFD0;
  height: 10%;
}

.calc-hour2 {
  @extend .calc-hour;
  background: #A2BFD0;
  height: 20%;
}

.calc-hour3 {
  @extend .calc-hour;
  background: #A2BFD0;
  height: 30%;
}

.calc-hour4 {
  @extend .calc-hour;
  background: #A2BFD0;
  height: 40%;
}

.calc-hour5 {
  @extend .calc-hour;
  background: #A2BFD0;
  height: 50%;
}

.calc-hour6 {
  @extend .calc-hour;
  background: #A2BFD0;
  height: 60%;
}

.calc-hour7 {
  @extend .calc-hour;
  background: #A2BFD0;
  height: 70%;
}

.calc-hour8 {
  @extend .calc-hour;
  background: #A2BFD0;
  height: 80%;
}

.calc-hour9 {
  @extend .calc-hour;
  background: #A2BFD0;
  height: 90%;
}

.calc-hour10 {
  @extend .calc-hour;
  background: #A2BFD0;
  height: 100%;
}


.weekDay {
  border-right: 2px solid rgb(211, 211, 211) !important;
}

.weekDay td.drop-hours:last-child {
  border-right: 0 !important;
}

.weekDay td.drop-hours:first-child {
  border-left: 0 !important;
}

.th-name-tecnico {
  border-right: 1px solid black;
}

td.week.with-1-days {
  padding-left: 6px;
  padding-right: 0px;
}
