.history-container{
  font-size: 1rem;
  margin-top: 2.6em;
}

.history-container-title{
  background-color: #dcdbdb;
  border-radius: 4px 4px 0 0;
  cursor: pointer;
  text-align: center!important;
  font-size: 0.9rem;
  font-weight: 600;
  text-transform: uppercase;
  padding: 10px 0px;
  margin-top: 0.5em;
 /* margin-right: 20px;
  margin-left: 30px;*/
  margin-bottom: 20px;
}

.history-container-ico{
  width: 55px;
  margin: 0 auto;
  height: 50px;
  border-radius: 50% 50% 0 0;
  background-color: #dcdbdb;
  position: relative;
  margin-top: -40px;
  padding: 10px;
}

.history-list-container {
  font-size: 1rem;
}

.history-list-item {
  margin-right: 40px;
  margin-left: 40px;
  padding-bottom: 10px;
  padding-top: 10px;
  font-size: 0.9em;
  color: #000;
  border-bottom: 1px solid #dcdbdb;
}

.history-list-item-title {
  cursor: pointer;
}

.history-list-item-updated{

}

.history-list-item-changes{
  color: #a7a7a7;
  text-align: right!important;
}

.history-change-container {
  width: 100%;
  margin-top: 1em;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  font-size: 1rem;
  background-color: transparent !important;
}

.history-change {
  width: calc(50% - 1.8rem);
  margin: 0 0.9rem;
}

.history-change.previous {
  background-color: #faeceb;
}

.history-change.modified {
  background-color: #e4f1e6;
}

.history-change-title {
  justify-content: center !important;
  font-weight: 800;
  text-transform: capitalize;
  border-top: none !important;
}

.history-change-title.previous{
  color: #d63535;
}

.history-change-title.modified{
  color: #51a85f;
}

.history-change-item{
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  -o-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  padding: 0.8em;
  border-top: 1px dotted #212529;
  font-size: 0.8em;
}

.history-change-item-text {
  font-weight: 600;
  text-transform: uppercase;
}

.history-change-item-text.value{
  float: right;
}