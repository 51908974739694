//body modal
.c-modal-info__cell__body {
  margin-top: 0;

  &.transparent {
    opacity: .5;
  }

  //caja de cada orden
  .c-modal-info-cell__box {
    border: $border-width $border-style $color-blue-dark; //1.5px
    border-radius: $border-radius;
    padding: 0.4rem 1.8rem 0.4rem 0.4rem; //7px
    margin-bottom: 0.4375rem;
    //info orders
    .c-modal-info-cell__orders {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin: 0 auto 0.413rem auto;
      list-style-type: none;
      margin-bottom: 0;
      padding: 0;

      &.success {
        color: $color-success;
      }

      &.blue {
        color: $font-color-blue-dark;
      }

      &.warning {
        color: $font-color-red-dark;
      }

      &.medium {
        font-weight: 500;
      }

      .c-modal-info-cell__orders__item {
        //padding: 0.313rem 0.438rem;
        font-weight: 600;
        font-size: 0.75rem;
      }

      .c-modal-info-cell__orders__item-status {
        //padding: 0.313rem 0.438rem;
        font-weight: 600;
        font-size: 0.75rem;
        padding-right: 17px;
      }
    }

    .table {
      margin-bottom: 0;

      td {
        padding: 0;
        border: 0;
      }

    }
  }


  // background segun tipo: prevent, program, correct
  & .success {
    border-color: $color-success;
  }

  & .program {
    background-color: lighten($color-program, 20%);
  }

  & .prevent {
    background-color: lighten($color-prevent, 20%);
  }

  & .corrective {
    background-color: lighten($color-corrective, 20%);
  }

  // organizado en dos columnas
  &.clientSchedule {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .c-modal-info-cell__box {
      width: 50%;
      padding: 0;
      border: none;
      margin-bottom: 0;

      .c-modal-info-cell__orders {
        display: flex;
        justify-content: start;

        li {
          min-width: 100px;
          font-size: 0.75rem;
          color: #2D3B41;
          font-weight: 400;
        }

        li:nth-child(1) {
          font-style: italic;
          font-weight: 600;
          font-family: 'Roboto', sans-serif;
        }

        li:nth-child(2), li:nth-child(3) {
          font-weight: 600;
        }

        li:nth-child(2) {
          min-width: 60px;
        }

      }
    }
  }
}


//textos
.c-modal-info-cell__text {
  margin: 0 0 0.313rem 0;
  font-family: $font-base;
  font-size: 0.750rem;
  font-weight: 700;
  line-height: 1.5;
  //color: $font-color-dark;
  &.blue {
    color: $font-color-blue-dark;
  }

  &.blue--italic {
    color: $font-color-blue-dark;
    font-style: italic;
    font-weight: 300;
  }

  &.warning {
    color: $font-color-red-dark;
  }

  &.medium {
    font-weight: 600;
  }

  &.regular {
    margin-top: 0.713rem;
    font-weight: 400;
  }

  //iconos
  &::before {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    display: inline-block;
    margin-right: 0.438rem;
    color: $font-color-blue-dark;
  }

  &.place {
    &::before {
      content: '\f041';
    }
  }

  &.comment {
    &::before {
      content: '\f27a';
    }
  }

  &.technicals {
    &::before {
      content: '\f0c0';
    }
  }

  &.no-technicals {
    display: inline-block;
    margin-left: 10px;

    &::before {
      content: '\f235';
    }
  }
}
