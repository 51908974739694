//TABLA NUEVA VISITA PROGRAMADA
.table-edit{
    width: 100%;
    .table{
        font-size: $font-size;
        thead{
            th,
            td{
                vertical-align: middle;
                padding: 0.938em 0.875em;
                border-top: $border-width $border-style $color-gray-light;
                border-bottom: $border-width $border-style $color-gray-light;
                background-color: transparent;
                font-family: $font-base;
                font-size: 0.8em;
                font-weight: 600;
                color: $font-color-dark;
                text-transform: capitalize;
            }
        }
        tfoot{
            @extend thead;
            th{
                text-transform: none;
            }
        }
        tbody{
            @extend thead;
            th{
                vertical-align: middle;
                padding: 0.938em 0.875em;
                border-top:0;
                border-bottom:0;
                background-color: transparent;
                font-family: $font-base;
                font-size: 0.8em;
                font-weight: 600;
                color: $font-color-dark;
                text-transform: capitalize;
            }
            td{
                border-top: 0;
                border-bottom: 0;
                font-weight: 400;
            }
            tr{
              border-bottom: $border-width $border-style $color-border;

              &:last-child{
                    th,
                    td{
                        border-bottom: 0;
                    }
                }
            }
            &.border-end{
                tr{
                    &:last-child{
                        th,
                        td{
                            border-bottom: $border-width $border-style $color-border;
                        }
                    }
                }
            }
        }
        //link dentro de tabla
        a{
            color: $color-blue;
            &:hover{
                text-decoration: none;
                opacity: .85;
            }
        }
    }
    //select horas dentro de celda
    select,
    select.form-control,
    select.form-control:not([size]):not([multiple]){
        width: 100%;
        max-width: 60px;
        height: 30px;
        margin: 0.1em 0 0.1em auto;
        border: 0;
    }
}

//CELDA CON ICONOS ELIMINAR Y AGREGAR(opcional)
.remove-add{
    height: 100%;
    @include flexbox();
    @include justify-content(center);
    @include align-items(center);
    margin-left: 1em;
    a{
        width: auto;
        height: auto;
        position: relative;
        display: inline-block;
        margin-right: 0.8em;
        text-decoration: none;
        &:hover{
            text-decoration: none;
            opacity: .85;
        }
        &:last-child{
            margin-right: 0;
        }
        &.edit{
            color: $color-blue-dark;
        }
        &.remove{
            color: $font-color-red-dark;
        }
        &.add{
            @include flexbox();
            @include align-items(center);
            color: $color-green;
            .add__text{
                min-width: 60px;
                margin-top: 1px;
                margin-left: 5px;
                font-family: $font-base;
                font-size: 0.600em;
                font-weight: 400;
            }
        }
        .fas,
        .far,
        .fa{
            width: 100%;
            height: 100%;
            display: block;
            font-size: 1em;
        }
    }
}
