//INFO COLORES SOBRE TABLA
.color-info-wrapper{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 0;
    @include hide();
    .color-info-wrapper__item{
        margin-right: 0.857em;
        &:last-child{
            margin-right: 0;
        }
        //cuadrado color
        .color-info{
            width: 10px;
            height: 10px;
            display: inline-block;
            &.urgent{
                background-color: #FF0000;
            }
            &.preventive{
                background-color: #A2BFD0;
            }
            &.corrective{
                background-color: #F99494;
            }
            &.tec-colaborate{
                background-color: #A2BFD0;
            }
            &.holidays{
                background-color: #E2D1D1;
            }
            &.other-events{
                background-color: #DAA53D;
            }
        }
        //texto
        .color-info-text{
            display: inline-block;
            margin-left: 0.571em; //8px
            font-family: $font-base;
            font-size: 1em; //14px
            font-weight: 400;
            line-height: 1.5;
            text-align: left;
            text-transform: capitalize;
            color: $font-color-dark;
        }
    }
}
.to-transform-none {
    text-transform: none !important;
}

//TABLAS
table{
    a{
        color: #007BFF;
    }
}
.table{
    overflow: hidden;
    thead{
        th{
            a{
                text-decoration: none;
            }
            .fas,
            .fa,
            .far{
                display: inline-block;
                margin-left: 0.2rem;
                font-size: inherit;
                color: $color-asc-desc;
            }
        }
    }
    td{
        .form-group{
            margin-bottom: 0;
        }
    }
    //tabla interna
    &.label-days{
        td{
            padding-left: 0;
            padding-right: 0;
            text-align: center;
        }
    }
}

//TABLA: condiciones de órdenes de trabajo preventivas
.table-four{
    font-size: $font-size;
    .table{
        overflow: auto;
        thead{
            height: 40px;
            border-bottom: 1px solid $color-gray-dark;
            th{
                //width: 90px;
                vertical-align: bottom;
                position: relative;
                padding: 0.2rem 0.4rem;
                font-family: $font-base;
                font-size: 0.688em; //11px
                font-weight: 400;
                text-transform: capitalize;
                text-align: left;
                color: $font-color-medium;
                background-color: transparent;
                border-top: 0;
                border-bottom: 1px solid #D3D3D3;
                &::before{
                    content: '';
                    width: 1px;
                    height: 14px;
                    position: absolute;
                    bottom: 2px;
                    left: 0;
                    background-color: #707070;
                }
                &:last-child::after{
                    content: '';
                    width: 2px;
                    height: 14px;
                    position: absolute;
                    bottom: 2px;
                    right: 0;
                    background-color: #707070;
                }
                .year{
                    font-size: 0.818em; //9px
                    color: #b3b3b3;
                }
            }
            //&:last-child::before{
            //    content: '';
            //    width: 1px;
            //    height: 14px;
            //    position: absolute;
            //    bottom: 2px;
            //    right: 0;
            //    background-color: red;
            //}
        }
        tbody{
          tr {
            &.preventivas {
              height: 40px;

            }
          }
            td{
                padding: 0.2em;
                border-bottom: 1px solid $color-border;
            }
        }
    }
    //totales
    &.table-four--small{
        .table{
            thead{
                border-bottom: 0;
                th{
                    border-bottom: 1px solid $color-gray-dark;
                    &::before{
                        display: none;
                    }
                    &.cell-total{
                        text-transform: uppercase;
                        &:first-child{
                            border-right: 5px solid #fff;
                        }
                        &:last-child{
                            border-left: 5px solid #fff;
                        }
                        span{
                            display: block;
                            line-height: 1.1;
                            text-transform: capitalize;
                        }
                    }
                }
            }
            tbody{
                td{
                    border: 0;
                    &:first-child{
                        padding-left: 0;
                    }
                    &:last-child{
                        padding-right: 0;
                    }
                    .total{
                        height: 33px;
                        display: flex;
                        justify-content: flex-end;
                        align-items: flex-end;
                        padding-right: 0.3em;
                        font-family: $font-base;
                        font-size: 0.750em; //12px
                        font-weight: 800;
                        background-color:  $color-drag-item;
                        .small{
                            display: inline-block;
                            position: relative;
                            bottom: 0.5em;
                            font-size: 0.55em;
                        }
                    }
                }
            }
        }
    }
}

//TABLA NÚMERO ORDEN DE TRABAJO
.table-edit{
    &.second{
        th,
        td{
            &.title-cell{
                padding-bottom: 0;
                border-bottom: 0;
                font-family: $font-base;
                font-size: 0.8rem;
                font-weight: 800;
                line-height: $line-height;
                color: $color-blue-dark;
            }
            &.notes-cell{
                @extend .title-cell;
                font-weight: 400;
                color: $color-gray-dark;
            }
        }
        thead{
            th{
                border-top: 0;
                font-weight: 600;
            }
        }
    }
}
.no-attached,
.yes-attached{
    display: inline-block;
    vertical-align: middle;
    &::before{
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        margin-right: 0.5rem;
    }
}
.no-attached{
    margin-left: 0.3rem;
    &::before{
        content: '\f057';
        color: $color-gray-dark;
    }
}
.yes-attached{
    margin-right: 0.3rem;
    &::before{
        content: '\f0c6';
        color: #E70C21;
    }
}

//BLOQUE ESTILO TABLA
.block-table{
    .block-table__body{
        box-shadow: $box-shadow;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
}

//tabla main-list
.table-main-list{
    .table{
        font-size: 0.8rem;
        thead,
        tbody{
            th,
            td{
                min-width: 60px;
                vertical-align: middle;
                //media queries
                @include mq('desktop') {
                    white-space: nowrap;
                }
                div{
                    div{
                        padding-left: 2px;
                        padding-right: 2px;
                    }
                }
            }
        }
    }
}

//Tabla work unit
.table-second{
    .table{
        &.drop-work-unit{
            width: auto;
            margin-bottom: 0.1rem;
            thead{
                th,
                td{
                    .th-bottom__item{
                        //min-width: 90px;
                    }
                    &.th-bottom__item{
                        vertical-align: middle;
                    }
                }
            }
            tbody{
                th,
                td{
                    border-bottom: 0;
                    .th-bottom__item{
                       // min-width: 90px;
                    }
                    &.th-bottom__item{
                        vertical-align: middle;
                    }
                }
                td{
                    border-top: 0;
                    border-left: 0;
                    border-right: 0;
                    border-bottom: 0;
                    table{
                        width: auto;
                        height: 100%;
                        border: 0;
                        box-shadow: none;
                        td{
                            width: auto;
                            height: 100%;
                            border-top: 1px solid #f1f1f1;
                            border-left: 1px solid #f1f1f1;
                            border-right: 1px solid #f1f1f1;
                            border-bottom: 1px solid #f1f1f1;
                        }
                    }
                }
            }
        }
    }
}



//iconos ver y editar
.list-quickview-button,
.list-edit-button{
    width: 20px;
    height: 20px;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    border-radius: $border-radius;
    cursor: pointer;
    .fas,
    .far,
    .fa{
        position: absolute;
        top: 50%;
        left: 50%;
        @include translate(-50%, -50%);
        font-size: 12px;
        color: $font-color-white;
    }
    &:hover{
        opacity: .85;
    }
}
.list-quickview-button{
    margin-right: 0.4rem;
    background-color: #6299B9;
}
.list-edit-button{
    background-color: #A2BFD0;
}
.edit{
    @extend .list-edit-button;
     margin-right: 0.2rem;
}
.remove{
    @extend .list-quickview-button;
    margin-right: 0;
    margin-left: 0.2rem;
    background-color: $color-red-dark;
}
.inactive{
   background-color: #E9ECEF;
}
.finSemana {
    background-color: #e1e5e9;
}
.no-cabe-derecha{
    border-right:  5px solid $color-red-dark;
}
.no-cabe-izquierda{
    border-left: 5px solid $color-red-dark;
}
//iconos si y no con texto: si, no
.icon-and-text{
    .icon-and-text__item{
        display: flex;
        align-items: center;
        .icon-and-text__item__text{
            margin-left: 0.4rem;
        }
        .fas,
        .fa,
        .far{
            font-size: 1.2em;
            &.fa-check-circle{
                color: $color-gray-light;
            }
            &.fa-times-circle{
                color: $color-red;
            }
        }

    }
}

//para el spinner
.list-component{
    position: relative;
}

//se le pone un min-heihgt a tabla responsive para que se vea el loader cuando está vacía
.table-responsive{
    &.list-component{
        min-height: 200px;
    }
}

//tabla planificaciones
table{
    &.drop-work-unit{
        .label-days{
            > tr{
                td{
                    padding: 0;
                    border: 0;
                    border-right: $border-width $border-style $color-border;
                    &:last-child{
                        border-right: 0;
                    }
                }
            }
        }
        tr{
            td{
                &.day{
                    padding: 0;
                    border: 0;
                    border-right: $border-width $border-style $color-border;
                    &:last-child{
                        border-right: 0;
                    }
                    .th-bottom{
                        .day{
                            font-size: 0.588rem;
                            padding-left: 2px;
                        }
                    }
                }
            }
        }
    }
}



