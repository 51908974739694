body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@charset "UTF-8";
.border-color-green {
  outline: 3px solid #28a745 !important;
  outline-offset: -3px; }

.color-text-green {
  color: #28a745 !important; }

.seleccionada {
  outline: 3px solid #d39e00 !important;
  outline-offset: -3px; }

.react-datepicker-popper {
  z-index: 11 !important; }

.visible-text {
  text-indent: 0;
  white-space: normal;
  overflow: visible !important; }

.modal-push *[class="row"] {
  margin-left: -8px;
  margin-right: -8px; }

.modal-push *[class*="col-"] {
  padding-left: 8px;
  padding-right: 8px; }

.web-body .form-group {
  margin-bottom: 1.2rem; }
  .web-body .form-group .form-checkform-check-inline .Select2Component {
    margin-bottom: 0; }

.o-flex-end {
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  -ms-justify-content: flex-end;
  justify-content: flex-end;
  -ms-flex-pack: flex-end; }

h3,
.title-tertiary {
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-size: 1em;
  font-weight: 600;
  color: #707070; }
  h3 span,
  .title-tertiary span {
    font-weight: 500; }

.text-standar,
p.text-standar {
  font-family: "Roboto", sans-serif;
  font-size: 0.875em;
  font-weight: 400;
  color: #2D3B41; }

.dropdown-sublist {
  width: auto;
  min-width: 150px;
  position: absolute;
  top: 2.2rem;
  right: 0;
  z-index: 99;
  padding: 0.438em 0;
  background-color: white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  font-size: 0.75rem; }
  @media only screen and (min-width: 768px) {
    .dropdown-sublist {
      font-size: 1rem; } }
  .dropdown-sublist .dropdown-sublist__head, .dropdown-sublist .dropdown-sublist__item {
    margin-bottom: 0.2em;
    padding: 0.500em 1.286em;
    font-family: "Roboto", sans-serif;
    font-size: 0.875em;
    font-weight: 400;
    text-transform: capitalize;
    text-align: left;
    color: #BC212C;
    border-bottom: 1px solid #95989A; }
  .dropdown-sublist .dropdown-sublist__item {
    padding: 0;
    margin-bottom: 0.1em;
    color: #6C757D;
    border-bottom: 0; }
    .dropdown-sublist .dropdown-sublist__item a {
      width: 100%;
      height: 100%;
      display: block;
      padding: 0.500em 2.800em;
      font-family: inherit;
      color: inherit;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease; }
      .dropdown-sublist .dropdown-sublist__item a.item-active, .dropdown-sublist .dropdown-sublist__item a:hover {
        color: #2D3B41;
        background-color: #eee;
        text-decoration: none;
        cursor: pointer; }

.table-edit .table-responsive .table tbody.border-end tr.editing {
  position: relative; }
  .table-edit .table-responsive .table tbody.border-end tr.editing td .dropdown-sublist.table {
    table-layout: fixed;
    max-width: 1250px;
    top: auto;
    right: auto;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); }
    @media only screen and (min-width: 768px) {
      .table-edit .table-responsive .table tbody.border-end tr.editing td .dropdown-sublist.table {
        table-layout: auto; } }
    .table-edit .table-responsive .table tbody.border-end tr.editing td .dropdown-sublist.table tr td {
      font-size: 0.8rem;
      color: #2D3B41; }
      .table-edit .table-responsive .table tbody.border-end tr.editing td .dropdown-sublist.table tr td input[type="checkbox"] {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: 16px;
        height: 16px;
        position: relative;
        border: 1px solid #707070; }
        .table-edit .table-responsive .table tbody.border-end tr.editing td .dropdown-sublist.table tr td input[type="checkbox"]:checked::after {
          font-family: "Font Awesome 5 Free";
          content: '\F00C';
          font-size: 11px;
          font-weight: 900;
          color: #3D708D;
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%); }
        .table-edit .table-responsive .table tbody.border-end tr.editing td .dropdown-sublist.table tr td input[type="checkbox"]:checked:focus {
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2); }
    .table-edit .table-responsive .table tbody.border-end tr.editing td .dropdown-sublist.table tr td[scope="row"] + td {
      font-weight: 600;
      color: #3D708D; }
    .table-edit .table-responsive .table tbody.border-end tr.editing td .dropdown-sublist.table tr.tr-selected {
      background-color: #eee; }
    .table-edit .table-responsive .table tbody.border-end tr.editing td .dropdown-sublist.table tr.tr-error td[scope="row"] + td {
      color: #BC212C; }
    .table-edit .table-responsive .table tbody.border-end tr.editing td .dropdown-sublist.table tr.tr-error td:last-child, .table-edit .table-responsive .table tbody.border-end tr.editing td .dropdown-sublist.table tr.tr-error td:nth-child(6) {
      color: #BC212C; }

.table .Select-menu-outer {
  min-width: 280px;
  z-index: 9999; }

.table .Select {
  min-width: 160px; }

.table-edit .table tbody tr.editing td {
  padding-left: 0.4rem;
  padding-right: 0.4rem; }

.list-columns-modal-container {
  border: 0;
  padding: 0; }

.ReactModal__Overlay {
  opacity: 0;
  -webkit-transition: opacity 200ms ease-in-out;
  transition: opacity 200ms ease-in-out;
  z-index: 999; }

.ReactModal__Overlay li {
  z-index: 10; }

/*Hace que al abrir el modal oculte el scroll global de la página*/
/*
.ReactModal__Body--open,
{
    overflow-y: hidden;
    //padding-right: 17px;
    .ReactModal__Overlay {
        opacity: 1;
    }
}
*/
.ReactModal__Overlay--after-open.ReactModal__Overlay {
  opacity: 1; }

.ReactModal__Content.top-to-bottom {
  width: 98%;
  min-width: 310px;
  max-width: 800px;
  height: auto !important;
  max-height: 80%;
  position: fixed;
  top: 0;
  left: 50%;
  -webkit-transform: translate(-50%, -500%);
          transform: translate(-50%, -500%);
  z-index: 99;
  background-color: #fff;
  font-size: 0.8rem;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  overflow-x: hidden; }
  .ReactModal__Content.top-to-bottom.ReactModal__Content--after-open {
    top: 12%;
    -webkit-transform: translate(-50%);
            transform: translate(-50%); }
  .ReactModal__Content.top-to-bottom .card-title {
    margin-top: 0;
    margin-bottom: 0; }

.ReactModal__Content.top-to-bottom-client {
  width: 98%;
  min-width: 310px;
  max-width: 800px;
  height: auto !important;
  max-height: 80%;
  position: fixed;
  top: 0;
  left: 50%;
  -webkit-transform: translate(-50%, -500%);
          transform: translate(-50%, -500%);
  z-index: 99;
  background-color: #fff;
  font-size: 0.8rem;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease; }
  .ReactModal__Content.top-to-bottom-client.ReactModal__Content--after-open {
    top: 12%;
    -webkit-transform: translate(-50%);
            transform: translate(-50%); }
  .ReactModal__Content.top-to-bottom-client .card-title {
    margin-top: 0;
    margin-bottom: 0; }

.ReactModal__Content {
  width: 98%; }

.ReactModal__Content.list-columns-modal,
.ReactModal__Content.right-to-left {
  min-width: 310px;
  max-width: 810px;
  height: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  -webkit-transform: translateX(-800px);
          transform: translateX(-800px);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  z-index: 99;
  padding: 2.625em 0;
  font-size: 0.8rem; }
  .ReactModal__Content.list-columns-modal.ReactModal__Content--after-open,
  .ReactModal__Content.right-to-left.ReactModal__Content--after-open {
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  .ReactModal__Content.list-columns-modal .filters-block__form,
  .ReactModal__Content.right-to-left .filters-block__form {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding-left: 0;
    padding-right: 0; }
    .ReactModal__Content.list-columns-modal .filters-block__form .form-group,
    .ReactModal__Content.right-to-left .filters-block__form .form-group {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
      -moz-justify-content: center;
      -ms-justify-content: center;
      justify-content: center;
      -ms-flex-pack: center; }
  .ReactModal__Content.list-columns-modal .checkbox-list,
  .ReactModal__Content.right-to-left .checkbox-list {
    width: 98%;
    min-width: 150px;
    max-width: 100%;
    height: calc(100vh - 7.4rem);
    min-height: 300px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
    padding-right: 0;
    list-style-type: none; }
    @media only screen and (min-width: 992px) {
      .ReactModal__Content.list-columns-modal .checkbox-list,
      .ReactModal__Content.right-to-left .checkbox-list {
        -webkit-flex-direction: column;
        -moz-flex-direction: column;
        flex-direction: column; } }
    .ReactModal__Content.list-columns-modal .checkbox-list li,
    .ReactModal__Content.right-to-left .checkbox-list li {
      width: 100%;
      margin-bottom: 0.4rem; }
  .ReactModal__Content.list-columns-modal .form-group.DateInputField,
  .ReactModal__Content.right-to-left .form-group.DateInputField {
    width: 172px !important; }
  .ReactModal__Content.list-columns-modal .table-edit .table tbody tr.editing td,
  .ReactModal__Content.right-to-left .table-edit .table tbody tr.editing td {
    padding-left: 0.3rem;
    padding-right: 0.3rem; }
  .ReactModal__Content.list-columns-modal .remove-add,
  .ReactModal__Content.right-to-left .remove-add {
    margin-left: 0; }

.modal-push {
  width: 100%;
  min-width: 310px;
  max-width: 810px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  padding: 0 0 2.625em 0;
  background-color: #fff; }
  .modal-push .modal-head, .modal-push .modal-body, .modal-push .modal-body-filters, .modal-push .modal-footer {
    padding: 0.6em 1.2em; }
    .modal-push .modal-head .close-modal, .modal-push .modal-body .close-modal, .modal-push .modal-body-filters .close-modal, .modal-push .modal-footer .close-modal {
      width: 100%;
      height: 100%;
      display: block;
      font-size: 1.65em;
      color: #2D3B41; }
      .modal-push .modal-head .close-modal .fas, .modal-push .modal-body .close-modal .fas, .modal-push .modal-body-filters .close-modal .fas, .modal-push .modal-footer .close-modal .fas,
      .modal-push .modal-head .close-modal .fa,
      .modal-push .modal-body .close-modal .fa,
      .modal-push .modal-body-filters .close-modal .fa,
      .modal-push .modal-footer .close-modal .fa,
      .modal-push .modal-head .close-modal .far,
      .modal-push .modal-body .close-modal .far,
      .modal-push .modal-body-filters .close-modal .far,
      .modal-push .modal-footer .close-modal .far {
        font-size: inherit;
        color: inherit; }
      .modal-push .modal-head .close-modal:hover, .modal-push .modal-body .close-modal:hover, .modal-push .modal-body-filters .close-modal:hover, .modal-push .modal-footer .close-modal:hover {
        opacity: .9; }
  .modal-push .form-radio-label {
    max-height: 20px;
    font-size: 0.65rem;
    overflow-y: unset !important; }
  .modal-push .modal-body {
    height: 100vh;
    max-height: calc(100vh - 150px);
    overflow-y: auto;
    padding: 0.6em;
    border-bottom: 0; }
    .modal-push .modal-body.full-height {
      height: 98%;
      max-height: 100%; }
  .modal-push .modal-body-filters {
    height: 100%;
    max-height: calc(100vh - 150px);
    overflow-y: visible;
    padding-top: 1.6em;
    padding-bottom: 1.6em;
    border-bottom: 0; }
    .modal-push .modal-body-filters.full-height {
      height: 98%;
      max-height: 100%; }
  .modal-push .modal-footer {
    width: 100%;
    height: 180px;
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-top: 30px;
    border-bottom: 0; }
  .modal-push .modal-title,
  .modal-push .close,
  .modal-push .edit-modal {
    height: 2.375rem;
    /*38px*/ }
  .modal-push .modal-title {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    padding: 0;
    background-color: white; }
    .modal-push .modal-title img {
      margin-right: 0.875em; }
    .modal-push .modal-title h2,
    .modal-push .modal-title p {
      margin: 0;
      font-family: "Raleway", sans-serif;
      font-size: 1.250em;
      /*20px*/
      font-weight: 300;
      text-transform: capitalize;
      letter-spacing: 1px;
      color: #2D3B41; }
      .modal-push .modal-title h2 span,
      .modal-push .modal-title p span {
        font-weight: 600; }
  
  .modal-push .close,
  .modal-push .edit-modal {
    width: 2.500rem;
    float: none;
    padding: 0;
    margin-left: 0.5em;
    font-size: 1rem;
    /*16x*/
    font-weight: 400;
    text-shadow: none;
    color: #D0D7DB;
    border: 1px solid #D0D7DB;
    border-radius: 4px;
    opacity: 1;
    background-color: #fff;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    cursor: pointer; }
    .modal-push .close:hover span, .modal-push .edit-modal:hover span {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
      color: #2D3B41; }
    .modal-push .close:focus, .modal-push .edit-modal:focus {
      box-shadow: 0 0 0 0.15rem rgba(0, 0, 0, 0.25);
      outline: 0; }
    .modal-push .close span, .modal-push .edit-modal span {
      width: 100%;
      height: 100%;
      display: block;
      font-size: inherit;
      line-height: 2.375rem; }
    .modal-push .close.fusion, .modal-push .fusion.edit-modal {
      margin-left: 0;
      border-radius: 0 4px 4px 0; }
  .modal-push .edit-modal {
    border-right: 0;
    border-radius: 4px 0 0 4px; }
  .modal-push .modal-title-info {
    margin-top: 0.300em;
    margin-bottom: 0;
    font-family: "Roboto", sans-serif;
    font-size: 0.750em;
    font-weight: 400;
    color: #6C757D; }

.ReactModal__Content.right-to-left {
  left: auto;
  right: 0;
  -webkit-transform: translateX(800px);
          transform: translateX(800px);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease; }
  .ReactModal__Content.right-to-left.ReactModal__Content--after-open {
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  .ReactModal__Content.right-to-left .modal-push .modal-body {
    padding-top: 0.5em;
    padding-bottom: 1em; }

.ReactModal__Content.center.ReactModal__Content--after-open {
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center; }

.ReactModal__Content.center .modal-push {
  width: 100%;
  height: auto;
  max-height: 450px;
  position: static;
  padding: 0;
  background-color: #fff; }
  .ReactModal__Content.center .modal-push .form-group {
    margin-bottom: 0.2rem; }
  .ReactModal__Content.center .modal-push .modal-head, .ReactModal__Content.center .modal-push .modal-body, .ReactModal__Content.center .modal-push .modal-body-filters, .ReactModal__Content.center .modal-push .modal-footer {
    background-color: #f6f6f6;
    border-bottom: 0; }
  .ReactModal__Content.center .modal-push .modal-title {
    background-color: transparent;
    font-family: "Roboto", sans-serif;
    font-size: 0.875rem;
    font-weight: 500;
    color: #707070; }
    .ReactModal__Content.center .modal-push .modal-title p {
      font-family: inherit;
      font-size: inherit;
      font-weight: inherit; }
      .ReactModal__Content.center .modal-push .modal-title p span {
        font-weight: 600; }
    .ReactModal__Content.center .modal-push .modal-title::before {
      font-family: "Font Awesome 5 Free";
      content: '\F12A';
      font-weight: 900;
      opacity: .6;
      display: inline-block;
      margin-right: 0.813rem; }
  .ReactModal__Content.center .modal-push .modal-body {
    padding-top: 0.5rem;
    padding-bottom: 0;
    background-color: #fff; }
  .ReactModal__Content.center .modal-push .modal-body-filters {
    padding-top: 0.5rem;
    padding-bottom: 0;
    background-color: #fff; }
  .ReactModal__Content.center .modal-push .modal-center-text, .ReactModal__Content.center .modal-push .checkbox-list .drag-li {
    font-family: "Roboto", sans-serif;
    font-size: 0.8rem;
    font-weight: 400;
    text-align: left;
    line-height: 1.5;
    color: #212529; }
  .ReactModal__Content.center .modal-push small.js-error, .ReactModal__Content.center .modal-push small.help-block, .ReactModal__Content.center .modal-push small.js-error {
    color: #6C757D; }
  .ReactModal__Content.center .modal-push .checkbox-list {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    margin-top: 0.3rem;
    padding: 0;
    list-style-type: none; }
    .ReactModal__Content.center .modal-push .checkbox-list .drag-li {
      margin: 0 0.938rem 0.2rem 0;
      color: #3D708D; }
  .ReactModal__Content.center .modal-push .modal-footer {
    height: auto;
    margin: 0;
    padding-top: 0;
    background-color: white;
    border-top: 0; }
    .ReactModal__Content.center .modal-push .modal-footer .btn {
      margin-right: 0.938rem; }
      .ReactModal__Content.center .modal-push .modal-footer .btn:last-child {
        margin-right: 0; }

.modal-center-text, .ReactModal__Content.center .modal-push .checkbox-list .drag-li {
  margin-bottom: 0; }

/*.ReactModal__Body--open{
    .ReactModal__Content{
        transform: translateX(0);
        transition: all 500ms ease;
    }
}*/
.nav-tabs--modal {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  margin-bottom: 0;
  font-size: 1rem; }
  .nav-tabs--modal .nav-item {
    margin-bottom: 0;
    margin-left: 15px;
    margin-right: 15px;
    padding-bottom: 0.5em; }
    .nav-tabs--modal .nav-item .nav-link {
      padding-left: 0;
      padding-right: 0;
      font-family: "Roboto", sans-serif;
      font-size: 0.875em;
      font-weight: 400;
      text-transform: capitalize;
      color: #6C757D; }
      .nav-tabs--modal .nav-item .nav-link.active {
        font-weight: 500;
        color: #E51227; }

.tab-content--modal {
  margin-top: 0.8em; }

.editable {
  font-size: 1rem; }
  .editable .last-modified {
    margin-top: -0.8rem;
    font-family: "Roboto", sans-serif;
    font-size: 0.750rem;
    font-weight: 400;
    text-align: left;
    line-height: 1.5;
    color: #6C757D; }
  .editable .block {
    margin-top: 0.5em;
    margin-bottom: 0.5em; }
    .editable .block:first-child {
      margin-top: 0; }
    .editable .block.block--border {
      width: 100%;
      max-width: 650px;
      margin-bottom: 1.2rem;
      padding: 1em;
      border: 1px solid #CED4DA;
      border-radius: 4px; }
      .editable .block.block--border .group {
        margin: 0; }
      .editable .block.block--border .control {
        font-size: 0.8rem; }
  .editable .group {
    margin: 0.25em 0; }
  .editable .control {
    margin-bottom: 0.25em;
    font-family: "Roboto", sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    text-align: left;
    line-height: 1.5;
    color: #6C757D; }
  .editable .div-control {
    min-height: 2rem;
    padding: 0;
    border: 0;
    font-family: "Roboto", sans-serif;
    font-size: 0.875em;
    font-weight: 500;
    color: #212529; }
    .editable .div-control.double .div-control__name, .editable .div-control.double .div-control__phone {
      margin-bottom: 0.25rem;
      font-family: "Roboto", sans-serif;
      font-weight: 400;
      text-transform: capitalize;
      color: #2D3B41; }
      .editable .div-control.double .div-control__name::before, .editable .div-control.double .div-control__phone::before {
        font-family: "Font Awesome 5 Free";
        content: '\F007';
        font-weight: 900;
        display: inline-block;
        margin-right: 0.4rem;
        color: #28A745; }
    .editable .div-control.double .div-control__phone {
      margin-bottom: 0;
      text-transform: none; }
      .editable .div-control.double .div-control__phone::before {
        content: '\F095'; }
    .editable .div-control.red {
      color: #E51227; }
    .editable .div-control.ubicacion::before {
      font-family: "Font Awesome 5 Free";
      content: '\F3C5';
      font-weight: 900;
      display: inline-block;
      margin-right: 0.4rem;
      color: #3D708D; }
    .editable .div-control.zona::before {
      font-family: "Font Awesome 5 Free";
      content: '\F5A0';
      font-weight: 900;
      display: inline-block;
      margin-right: 0.4rem;
      color: #3D708D; }
  .editable .notice {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    font-family: "Roboto", sans-serif;
    font-size: 0.875em;
    font-weight: 400;
    text-align: left;
    line-height: 1.5;
    color: #212529; }
    .editable .notice::before {
      font-family: "Font Awesome 5 Free";
      content: '';
      font-weight: 900;
      display: inline-block;
      margin-right: 0.4rem;
      color: #28A745; }
    .editable .notice.exclamation::before {
      content: '\F06A'; }
    .editable .notice.comment::before {
      content: '\F27A'; }
  .editable .attach-images {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap; }
    .editable .attach-images .attach-images__item {
      width: auto;
      min-width: 80px;
      max-width: 164px;
      height: auto;
      min-height: 80px;
      max-height: 104px;
      margin: 0.15rem; }
      .editable .attach-images .attach-images__item img {
        max-width: 100%;
        height: auto;
        display: block; }

.modal-info {
  background-color: rgba(255, 255, 255, 0.95); }
  .modal-info .modal-dialog {
    width: 96%;
    max-width: 770px; }
    .modal-info .modal-dialog .modal-content {
      border: 1px solid #F6F6F6;
      border-radius: 0;
      font-size: 0.85rem; }
      @media only screen and (min-width: 768px) {
        .modal-info .modal-dialog .modal-content {
          font-size: 1rem; } }
      .modal-info .modal-dialog .modal-content .modal-header {
        background-color: #F6F6F6; }
        .modal-info .modal-dialog .modal-content .modal-header .modal-header__title {
          margin: 0;
          padding: 0;
          font-family: "Roboto", sans-serif;
          font-size: 0.875em;
          font-weight: 500;
          text-align: left;
          line-height: 1.5;
          color: #707070; }
          .modal-info .modal-dialog .modal-content .modal-header .modal-header__title.ico::before {
            font-family: 'Font Awesome 5 Free';
            font-weight: 900;
            content: '';
            display: inline-block;
            margin-right: 0.5rem;
            font-size: inherit;
            line-height: inherit;
            color: inherit; }
          .modal-info .modal-dialog .modal-content .modal-header .modal-header__title.ico.ico--info::before {
            content: '\F129'; }
      .modal-info .modal-dialog .modal-content .modal-body {
        padding: 2em; }
        .modal-info .modal-dialog .modal-content .modal-body .modal-info__list .modal-info__list__title, .modal-info .modal-dialog .modal-content .modal-body .modal-info__list .modal-info__list__item {
          margin: 1em 0 0.5em 0;
          font-family: "Roboto", sans-serif;
          font-size: 0.875rem;
          font-weight: 400;
          line-height: 1.5;
          color: #212529; }
          .modal-info .modal-dialog .modal-content .modal-body .modal-info__list .modal-info__list__title:first-child, .modal-info .modal-dialog .modal-content .modal-body .modal-info__list .modal-info__list__item:first-child {
            margin-top: 0; }
        .modal-info .modal-dialog .modal-content .modal-body .modal-info__list .modal-info__list__item {
          position: relative;
          margin-top: 0;
          margin-bottom: 0.25em;
          padding-left: 3em; }
          .modal-info .modal-dialog .modal-content .modal-body .modal-info__list .modal-info__list__item::before {
            font-family: 'Font Awesome 5 Free';
            content: '\F111';
            font-weight: 900;
            color: #DAA53D;
            position: absolute;
            top: 0;
            left: 1em;
            display: inline-block;
            margin-right: 0.5rem; }
      .modal-info .modal-dialog .modal-content .modal-footer {
        border-top: 0; }

.text-click {
  cursor: pointer; }
  .text-click:hover {
    text-decoration: underline; }

.drag-li {
  list-style-type: none;
  font-family: "Roboto", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  color: #212529;
  position: relative;
  z-index: 999; }
  .drag-li .non-selectable {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .drag-li input[type="checkbox"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 16px;
    height: 16px;
    position: relative;
    margin-right: 2rem;
    background-color: #dee2e6; }
    .drag-li input[type="checkbox"]:checked {
      background-color: #007BFF; }
      .drag-li input[type="checkbox"]:checked::before {
        content: '';
        width: 6px;
        height: 6px;
        position: absolute;
        top: 5px;
        left: 5px;
        background-color: white; }
  .drag-li label {
    font-family: inherit;
    font-size: inherit;
    color: inherit; }

.left-drag-hours {
  width: calc(100% - 90px); }

.drag-hours, .drag-hours2 {
  width: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  background-color: #A2BFD0;
  outline: 1px solid white;
  font-size: 1rem; }
  .drag-hours.drag-absolute, .drag-absolute.drag-hours2 {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2; }

.drag-hours-support {
  background: -webkit-repeating-linear-gradient(135deg, #3D708D 1px, #A2BFD0 7px);
  background: repeating-linear-gradient(-45deg, #3D708D 1px, #A2BFD0 7px); }

.drag-hours__item {
  width: 100%;
  height: 100%;
  margin: 0;
  background-color: inherit;
  font-size: inherit; }
  .drag-hours__item .drag-hours__item__content {
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    position: relative; }
  .drag-hours__item .num-hours {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: flex-end;
            align-items: flex-end;
    font-family: "Roboto", sans-serif;
    font-size: 0.750em;
    font-weight: 800;
    line-height: 100%;
    color: #2D3B41; }
    .drag-hours__item .num-hours .small {
      position: relative;
      top: 1px;
      display: inline-block;
      margin-left: 1px;
      font-size: 0.7em; }
  .drag-hours__item .bar-hours {
    height: 33px;
    margin-left: 2px;
    background-color: #A2BFD0; }
    table .drag-hours__item .bar-hours {
      margin-left: auto;
      margin-right: auto; }

.color-prevent {
  background-color: #A2BFD0; }

.color-program {
  background-color: #B197DC; }

.color-corrective {
  background-color: #EA9595; }

.color-events {
  background-color: #FFBF8A; }

.color-prevent--lines {
  background: -webkit-repeating-linear-gradient(135deg, white, white 3px, #A2BFD0 3px, #A2BFD0 6px);
  background: repeating-linear-gradient(-45deg, white, white 3px, #A2BFD0 3px, #A2BFD0 6px); }

.color-program--lines {
  background: -webkit-repeating-linear-gradient(135deg, white, white 3px, #B197DC 3px, #B197DC 6px);
  background: repeating-linear-gradient(-45deg, white, white 3px, #B197DC 3px, #B197DC 6px); }

.color-corrective--lines {
  background: -webkit-repeating-linear-gradient(135deg, white, white 3px, #EA9595 3px, #EA9595 6px);
  background: repeating-linear-gradient(-45deg, white, white 3px, #EA9595 3px, #EA9595 6px); }

.color-events--lines {
  background: -webkit-repeating-linear-gradient(135deg, white, white 3px, #FFBF8A 3px, #FFBF8A 6px);
  background: repeating-linear-gradient(-45deg, white, white 3px, #FFBF8A 3px, #FFBF8A 6px); }

.is-user-fixed {
  border-top: #343a40 1px solid;
  border-bottom: #343a40 1px solid; }

.is-date-fixed {
  border-right: #343a40 1px solid;
  border-left: #343a40 1px solid; }

table .drop-hours {
  position: relative; }

table .th-name-tecnico {
  width: 110px;
  height: 33px;
  background-color: white;
  z-index: 59;
  position: absolute; }

table .th-tecnico {
  width: 110px !important; }

button.btn,
a.btn,
.btn {
  width: auto;
  height: 1.875rem;
  padding: 0 3.125rem;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  font-family: "Roboto", sans-serif;
  font-size: 0.875rem;
  line-height: 1.875rem;
  text-align: center;
  text-transform: capitalize;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }
  button.btn.btn--medium,
  a.btn.btn--medium,
  .btn.btn--medium {
    max-width: 560px; }
  button.btn.btn--large,
  a.btn.btn--large,
  .btn.btn--large {
    height: 2.375rem;
    min-width: 240px; }
    button.btn.btn--large.with-icon-save,
    a.btn.btn--large.with-icon-save,
    .btn.btn--large.with-icon-save {
      position: relative; }
      button.btn.btn--large.with-icon-save::before,
      a.btn.btn--large.with-icon-save::before,
      .btn.btn--large.with-icon-save::before {
        font-family: "Font Awesome 5 Free";
        content: '\F0C7';
        font-weight: 900;
        height: 100%;
        position: absolute;
        top: 2px;
        left: 66px;
        font-size: 12px; }
  button.btn.btn--full,
  a.btn.btn--full,
  .btn.btn--full {
    max-width: 100%; }
  button.btn.btn--red, button.btn.btn--loading, button.btn.btn--loaded, button.btn.btn--closeModal,
  a.btn.btn--red,
  a.btn.btn--loading,
  a.btn.btn--loaded,
  a.btn.btn--closeModal,
  .btn.btn--red,
  button.btn.btn--loading,
  button.btn.btn--loaded,
  a.btn.btn--loading,
  a.btn.btn--loaded,
  .btn.btn--loading,
  .btn.btn--loaded,
  button.btn.btn--closeModal,
  a.btn.btn--closeModal,
  .btn.btn--closeModal {
    background-color: #BC212C;
    border-color: #BC212C;
    color: #fff; }
  button.btn.btn--grey,
  a.btn.btn--grey,
  .btn.btn--grey {
    background-color: white;
    border-color: #576267;
    color: #576267; }
  button.btn.btn--white,
  a.btn.btn--white,
  .btn.btn--white {
    background-color: white;
    border-color: #6C757D;
    color: #2D3B41; }
  button.btn.btn--disabled,
  a.btn.btn--disabled,
  .btn.btn--disabled {
    pointer-events: none;
    background-color: #E9ECEF;
    border-color: #CED4DA;
    color: #CED4DA; }
    button.btn.btn--disabled .fas,
    button.btn.btn--disabled .fa,
    button.btn.btn--disabled .far,
    a.btn.btn--disabled .fas,
    a.btn.btn--disabled .fa,
    a.btn.btn--disabled .far,
    .btn.btn--disabled .fas,
    .btn.btn--disabled .fa,
    .btn.btn--disabled .far {
      display: inline-block;
      margin-right: 0.25rem; }
  button.btn.btn--loader, button.btn.btn--orders,
  a.btn.btn--loader,
  a.btn.btn--orders,
  .btn.btn--loader,
  button.btn.btn--orders,
  a.btn.btn--orders,
  .btn.btn--orders {
    border-color: #BC212C;
    background-color: white;
    color: #BC212C; }
    button.btn.btn--loader .fas, button.btn.btn--orders .fas,
    button.btn.btn--loader .fa,
    button.btn.btn--orders .fa,
    button.btn.btn--loader .far,
    button.btn.btn--orders .far,
    a.btn.btn--loader .fas,
    a.btn.btn--orders .fas,
    a.btn.btn--loader .fa,
    a.btn.btn--orders .fa,
    a.btn.btn--loader .far,
    a.btn.btn--orders .far,
    .btn.btn--loader .fas,
    .btn.btn--orders .fas,
    .btn.btn--loader .fa,
    .btn.btn--orders .fa,
    .btn.btn--loader .far,
    .btn.btn--orders .far {
      display: inline-block;
      margin-right: 0.25rem; }
  button.btn.btn--orders,
  a.btn.btn--orders,
  .btn.btn--orders {
    height: 2.2rem;
    font-weight: 600; }
  button.btn.btn--loading .fas, button.btn.btn--loaded .fas,
  button.btn.btn--loading .fa,
  button.btn.btn--loaded .fa,
  button.btn.btn--loading .far,
  button.btn.btn--loaded .far,
  a.btn.btn--loading .fas,
  a.btn.btn--loaded .fas,
  a.btn.btn--loading .fa,
  a.btn.btn--loaded .fa,
  a.btn.btn--loading .far,
  a.btn.btn--loaded .far,
  .btn.btn--loading .fas,
  .btn.btn--loaded .fas,
  .btn.btn--loading .fa,
  .btn.btn--loaded .fa,
  .btn.btn--loading .far,
  .btn.btn--loaded .far {
    display: inline-block;
    margin-right: 0.25rem; }
  button.btn.btn--loaded,
  a.btn.btn--loaded,
  .btn.btn--loaded {
    pointer-events: none; }
  button.btn.btn--new,
  a.btn.btn--new,
  .btn.btn--new {
    height: 2.500rem;
    padding: 0 0.5rem;
    line-height: 2.500rem;
    background-color: white;
    font-size: 0.750rem;
    font-weight: 500;
    color: #576267; }
    button.btn.btn--new::after,
    a.btn.btn--new::after,
    .btn.btn--new::after {
      font-family: 'Font Awesome 5 Free';
      content: '\F0D7';
      font-size: 1rem;
      font-weight: 900;
      position: relative;
      bottom: 1px;
      display: inline-block;
      vertical-align: middle;
      margin-left: 1.2rem; }
    button.btn.btn--new:hover,
    a.btn.btn--new:hover,
    .btn.btn--new:hover {
      box-shadow: 0 3px 3px rgba(0, 0, 0, 0.34); }
    button.btn.btn--new:focus,
    a.btn.btn--new:focus,
    .btn.btn--new:focus {
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.34); }
  button.btn.btn--new-task,
  a.btn.btn--new-task,
  .btn.btn--new-task {
    background-color: white;
    border-color: #BC212C;
    color: #BC212C; }
    button.btn.btn--new-task::before,
    a.btn.btn--new-task::before,
    .btn.btn--new-task::before {
      font-family: 'Font Awesome 5 Free';
      content: '\F0FE';
      font-weight: 900;
      display: inline-block;
      margin-right: 0.5rem; }
  button.btn.btn--openModal,
  a.btn.btn--openModal,
  .btn.btn--openModal {
    width: 0.875rem;
    max-width: 100%;
    display: inline-block;
    vertical-align: middle;
    padding: 0;
    margin-left: 0.2rem;
    border: 0;
    outline: 0;
    background-color: transparent;
    cursor: pointer; }
    button.btn.btn--openModal .fa,
    button.btn.btn--openModal .fas,
    button.btn.btn--openModal .far,
    a.btn.btn--openModal .fa,
    a.btn.btn--openModal .fas,
    a.btn.btn--openModal .far,
    .btn.btn--openModal .fa,
    .btn.btn--openModal .fas,
    .btn.btn--openModal .far {
      padding: 0;
      font-size: 1.1em; }
  button.btn.btn--closeModal,
  a.btn.btn--closeModal,
  .btn.btn--closeModal {
    width: 10.000rem;
    max-width: 100%;
    background-color: white;
    border-color: #DAA53D;
    color: #DAA53D; }
    button.btn.btn--closeModal::before,
    a.btn.btn--closeModal::before,
    .btn.btn--closeModal::before {
      visibility: hidden;
      display: none; }
    button.btn.btn--closeModal:hover,
    a.btn.btn--closeModal:hover,
    .btn.btn--closeModal:hover {
      color: #fff;
      background-color: #DAA53D; }
  button.btn.btn-outline-secondary,
  a.btn.btn-outline-secondary,
  .btn.btn-outline-secondary {
    height: auto;
    padding: 0.375rem 0.75rem;
    line-height: 1.5; }
  button.btn.btn--points,
  a.btn.btn--points,
  .btn.btn--points {
    width: auto;
    max-width: 100%;
    height: auto;
    padding: 0.313rem 0.875rem;
    font-size: 1.125rem;
    color: #BC212C;
    background-color: transparent;
    border-radius: 4px; }
    @media only screen and (min-width: 992px) {
      button.btn.btn--points,
      a.btn.btn--points,
      .btn.btn--points {
        position: absolute;
        top: 0;
        right: 15px; } }
    button.btn.btn--points .fa,
    button.btn.btn--points .fas,
    button.btn.btn--points .far,
    a.btn.btn--points .fa,
    a.btn.btn--points .fas,
    a.btn.btn--points .far,
    .btn.btn--points .fa,
    .btn.btn--points .fas,
    .btn.btn--points .far {
      font-size: inherit;
      color: inherit; }
    button.btn.btn--points:hover,
    a.btn.btn--points:hover,
    .btn.btn--points:hover {
      box-shadow: 0 3px 3px rgba(0, 0, 0, 0.33); }
    button.btn.btn--points:focus,
    a.btn.btn--points:focus,
    .btn.btn--points:focus {
      outline: 0;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.33); }

.button-form-group {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem; }
  @media only screen and (min-width: 992px) {
    .button-form-group {
      margin-top: 2rem; } }
  .button-form-group.into-col {
    margin-bottom: 0; }
    .button-form-group.into-col .btn,
    .button-form-group.into-col a.btn,
    .button-form-group.into-col button.btn {
      width: 100%;
      padding: 0.375rem; }

.text-underline {
  text-decoration: underline; }

a.link-underline,
.link-underline {
  font-size: 0.8rem;
  color: #BC212C;
  text-decoration: underline;
  border: 0;
  cursor: pointer; }
  a.link-underline:hover,
  .link-underline:hover {
    text-decoration: none; }
  a.link-underline.disabled,
  .link-underline.disabled {
    color: #CED4DA; }
  a.link-underline.ico::before,
  .link-underline.ico::before {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    padding-right: 0.15rem; }
  a.link-underline.ico.ico--edit::before,
  .link-underline.ico.ico--edit::before {
    content: '\F044'; }

.filter-tags__right .link-underline::before {
  font-family: "Font Awesome 5 Free";
  content: '\F0C7';
  font-weight: 900;
  display: inline-block;
  margin-right: 0.4rem; }

.filter-tags, .filter-tags__block {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  font-size: 0.85rem; }
  @media only screen and (min-width: 768px) {
    .filter-tags, .filter-tags__block {
      font-size: 1rem; } }
  .filter-tags .filter-tags__left, .filter-tags__block .filter-tags__left {
    width: 100%;
    min-width: 180px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap; }
    @media only screen and (min-width: 768px) {
      .filter-tags .filter-tags__left, .filter-tags__block .filter-tags__left {
        width: calc(100% - 130px); } }
  .filter-tags .filter-tags__right, .filter-tags__block .filter-tags__right {
    width: 100%;
    margin-top: 1em;
    text-align: left; }
    @media only screen and (min-width: 768px) {
      .filter-tags .filter-tags__right, .filter-tags__block .filter-tags__right {
        width: 120px;
        margin-top: 0;
        text-align: right; } }

.filter-tags__block {
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  -ms-flex-pack: flex-start;
  margin-top: 0.2em;
  margin-right: 1.2em;
  margin-bottom: 0.2em; }
  @media only screen and (min-width: 768px) {
    .filter-tags__block {
      -webkit-flex-direction: row;
      -moz-flex-direction: row;
      flex-direction: row;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center; } }
  .filter-tags__block:last-child {
    margin-right: 0; }
  .filter-tags__block .filter-tags__block__label, .filter-tags__block .filter-tags__block__tags li .tag-item.tag-item--text {
    width: auto;
    margin: 0.2em 1em 0.2em 0;
    font-family: "Roboto", sans-serif;
    font-size: 0.7em;
    font-weight: 400;
    text-transform: uppercase;
    text-align: left; }
    @media only screen and (min-width: 768px) {
      .filter-tags__block .filter-tags__block__label, .filter-tags__block .filter-tags__block__tags li .tag-item.tag-item--text {
        max-width: 140px; } }
  .filter-tags__block .filter-tags__block__label2 {
    width: auto;
    margin: 0.2em 1em 0.2em 0;
    font-family: "Roboto", sans-serif;
    font-size: 0.7em;
    font-weight: 400;
    text-align: left; }
    @media only screen and (min-width: 768px) {
      .filter-tags__block .filter-tags__block__label2 {
        max-width: 140px; } }
  .filter-tags__block .filter-tags__block__tags {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style-type: none; }
    .filter-tags__block .filter-tags__block__tags li {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      margin: 0.2em 0.5em 0.2em 0;
      background-color: #e6e7e9;
      border: 1px solid #d8dbdd;
      border-radius: 4px; }
      .filter-tags__block .filter-tags__block__tags li:last-child {
        margin-right: 0; }
      .filter-tags__block .filter-tags__block__tags li .tag-item {
        display: inline-block;
        color: #2D3B41; }
        .filter-tags__block .filter-tags__block__tags li .tag-item.tag-item--text {
          width: calc(100% - 15px);
          min-width: 80px;
          padding: 0 5px;
          margin: 0;
          font-size: 0.66em;
          text-transform: capitalize;
          text-align: center; }
        .filter-tags__block .filter-tags__block__tags li .tag-item.tag-item--close {
          width: 15px;
          font-family: "Roboto", sans-serif;
          font-size: 0.8em;
          font-weight: 600;
          text-align: center;
          border-left: 1px solid #fff; }
          .filter-tags__block .filter-tags__block__tags li .tag-item.tag-item--close .fas,
          .filter-tags__block .filter-tags__block__tags li .tag-item.tag-item--close .fa,
          .filter-tags__block .filter-tags__block__tags li .tag-item.tag-item--close .far {
            font-size: 0.8em; }
          .filter-tags__block .filter-tags__block__tags li .tag-item.tag-item--close:hover {
            text-decoration: none;
            opacity: .9;
            color: #E51227; }

a.more-tags,
.more-tags {
  display: inline-block;
  margin-top: 0.1em;
  margin-bottom: 0.1em;
  font-family: "Roboto", sans-serif;
  font-size: 0.72em;
  font-weight: 600;
  color: #2D3B41;
  text-decoration: underline; }
  a.more-tags:hover,
  .more-tags:hover {
    color: inherit;
    text-decoration: none;
    opacity: .9; }

.visits {
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0.375em  0.750em;
  border: 1px solid #A2BFD0;
  font-size: 0.85rem; }
  @media only screen and (min-width: 768px) {
    .visits {
      font-size: 1rem; } }
  .visits .visits__text {
    width: calc(100% - 150px);
    min-width: 95px;
    margin: 0;
    font-family: "Roboto", sans-serif;
    font-size: 0.750em;
    font-weight: 400;
    text-transform: capitalize;
    color: #2D3B41; }
  .visits .right-drag-hours {
    width: 146px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap; }

.table-edit {
  width: 100%; }
  .table-edit .table {
    font-size: 1rem; }
    .table-edit .table thead th, .table-edit .table tfoot th, .table-edit .table tbody th,
    .table-edit .table thead td,
    .table-edit .table tfoot td,
    .table-edit .table tbody td {
      vertical-align: middle;
      padding: 0.938em 0.875em;
      border-top: 1px solid #6C757D;
      border-bottom: 1px solid #6C757D;
      background-color: transparent;
      font-family: "Roboto", sans-serif;
      font-size: 0.8em;
      font-weight: 600;
      color: #2D3B41;
      text-transform: capitalize; }
    .table-edit .table tfoot th {
      text-transform: none; }
    .table-edit .table tbody th {
      vertical-align: middle;
      padding: 0.938em 0.875em;
      border-top: 0;
      border-bottom: 0;
      background-color: transparent;
      font-family: "Roboto", sans-serif;
      font-size: 0.8em;
      font-weight: 600;
      color: #2D3B41;
      text-transform: capitalize; }
    .table-edit .table tbody td {
      border-top: 0;
      border-bottom: 0;
      font-weight: 400; }
    .table-edit .table tbody tr {
      border-bottom: 1px solid #D3D3D3; }
      .table-edit .table tbody tr:last-child th,
      .table-edit .table tbody tr:last-child td {
        border-bottom: 0; }
    .table-edit .table tbody.border-end tr:last-child th,
    .table-edit .table tbody.border-end tr:last-child td {
      border-bottom: 1px solid #D3D3D3; }
    .table-edit .table a {
      color: #007BFF; }
      .table-edit .table a:hover {
        text-decoration: none;
        opacity: .85; }
  .table-edit select,
  .table-edit select.form-control,
  .table-edit select.custom-select,
  .table-edit select.form-control:not([size]):not([multiple]),
  .table-edit select.custom-select:not([size]):not([multiple]) {
    width: 100%;
    max-width: 60px;
    height: 30px;
    margin: 0.1em 0 0.1em auto;
    border: 0; }

.remove-add {
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  margin-left: 1em; }
  .remove-add a {
    width: auto;
    height: auto;
    position: relative;
    display: inline-block;
    margin-right: 0.8em;
    text-decoration: none; }
    .remove-add a:hover {
      text-decoration: none;
      opacity: .85; }
    .remove-add a:last-child {
      margin-right: 0; }
    .remove-add a.edit {
      color: #3D708D; }
    .remove-add a.remove {
      color: #BC212C; }
    .remove-add a.add {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      color: #4B713D; }
      .remove-add a.add .add__text {
        min-width: 60px;
        margin-top: 1px;
        margin-left: 5px;
        font-family: "Roboto", sans-serif;
        font-size: 0.600em;
        font-weight: 400; }
    .remove-add a .fas,
    .remove-add a .far,
    .remove-add a .fa {
      width: 100%;
      height: 100%;
      display: block;
      font-size: 1em; }

.color-info-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  padding: 0;
  visibility: hidden;
  display: none; }
  .color-info-wrapper .color-info-wrapper__item {
    margin-right: 0.857em; }
    .color-info-wrapper .color-info-wrapper__item:last-child {
      margin-right: 0; }
    .color-info-wrapper .color-info-wrapper__item .color-info {
      width: 10px;
      height: 10px;
      display: inline-block; }
      .color-info-wrapper .color-info-wrapper__item .color-info.urgent {
        background-color: #FF0000; }
      .color-info-wrapper .color-info-wrapper__item .color-info.preventive {
        background-color: #A2BFD0; }
      .color-info-wrapper .color-info-wrapper__item .color-info.corrective {
        background-color: #F99494; }
      .color-info-wrapper .color-info-wrapper__item .color-info.tec-colaborate {
        background-color: #A2BFD0; }
      .color-info-wrapper .color-info-wrapper__item .color-info.holidays {
        background-color: #E2D1D1; }
      .color-info-wrapper .color-info-wrapper__item .color-info.other-events {
        background-color: #DAA53D; }
    .color-info-wrapper .color-info-wrapper__item .color-info-text {
      display: inline-block;
      margin-left: 0.571em;
      font-family: "Roboto", sans-serif;
      font-size: 1em;
      font-weight: 400;
      line-height: 1.5;
      text-align: left;
      text-transform: capitalize;
      color: #2D3B41; }

.to-transform-none {
  text-transform: none !important; }

table a {
  color: #007BFF; }

.table {
  overflow: hidden; }
  .table thead th a, .table-edit .table tfoot th a, .table-edit .table tbody th a {
    text-decoration: none; }
  .table thead th .fas, .table-edit .table tfoot th .fas, .table-edit .table tbody th .fas,
  .table thead th .fa,
  .table-edit .table tfoot th .fa,
  .table-edit .table tbody th .fa,
  .table thead th .far,
  .table-edit .table tfoot th .far,
  .table-edit .table tbody th .far {
    display: inline-block;
    margin-left: 0.2rem;
    font-size: inherit;
    color: #B3B3B3; }
  .table td .form-group {
    margin-bottom: 0; }
  .table.label-days td {
    padding-left: 0;
    padding-right: 0;
    text-align: center; }

.table-four {
  font-size: 1rem; }
  .table-four .table {
    overflow: auto; }
    .table-four .table thead, .table-four .table-edit .table tfoot, .table-edit .table-four .table tfoot, .table-four .table-edit .table tbody, .table-edit .table-four .table tbody {
      height: 40px;
      border-bottom: 1px solid #2D3B41; }
      .table-four .table thead th, .table-four .table-edit .table tfoot th, .table-edit .table-four .table tfoot th, .table-four .table-edit .table tbody th, .table-edit .table-four .table tbody th {
        vertical-align: bottom;
        position: relative;
        padding: 0.2rem 0.4rem;
        font-family: "Roboto", sans-serif;
        font-size: 0.688em;
        font-weight: 400;
        text-transform: capitalize;
        text-align: left;
        color: #576267;
        background-color: transparent;
        border-top: 0;
        border-bottom: 1px solid #D3D3D3; }
        .table-four .table thead th::before, .table-four .table-edit .table tfoot th::before, .table-edit .table-four .table tfoot th::before, .table-four .table-edit .table tbody th::before, .table-edit .table-four .table tbody th::before {
          content: '';
          width: 1px;
          height: 14px;
          position: absolute;
          bottom: 2px;
          left: 0;
          background-color: #707070; }
        .table-four .table thead th:last-child::after, .table-four .table-edit .table tfoot th:last-child::after, .table-edit .table-four .table tfoot th:last-child::after, .table-four .table-edit .table tbody th:last-child::after, .table-edit .table-four .table tbody th:last-child::after {
          content: '';
          width: 2px;
          height: 14px;
          position: absolute;
          bottom: 2px;
          right: 0;
          background-color: #707070; }
        .table-four .table thead th .year, .table-four .table-edit .table tfoot th .year, .table-edit .table-four .table tfoot th .year, .table-four .table-edit .table tbody th .year, .table-edit .table-four .table tbody th .year {
          font-size: 0.818em;
          color: #b3b3b3; }
    .table-four .table tbody tr.preventivas {
      height: 40px; }
    .table-four .table tbody td {
      padding: 0.2em;
      border-bottom: 1px solid #D3D3D3; }
  .table-four.table-four--small .table thead, .table-four.table-four--small .table-edit .table tfoot, .table-edit .table-four.table-four--small .table tfoot, .table-four.table-four--small .table-edit .table tbody, .table-edit .table-four.table-four--small .table tbody {
    border-bottom: 0; }
    .table-four.table-four--small .table thead th, .table-four.table-four--small .table-edit .table tfoot th, .table-edit .table-four.table-four--small .table tfoot th, .table-four.table-four--small .table-edit .table tbody th, .table-edit .table-four.table-four--small .table tbody th {
      border-bottom: 1px solid #2D3B41; }
      .table-four.table-four--small .table thead th::before, .table-four.table-four--small .table-edit .table tfoot th::before, .table-edit .table-four.table-four--small .table tfoot th::before, .table-four.table-four--small .table-edit .table tbody th::before, .table-edit .table-four.table-four--small .table tbody th::before {
        display: none; }
      .table-four.table-four--small .table thead th.cell-total, .table-four.table-four--small .table-edit .table tfoot th.cell-total, .table-edit .table-four.table-four--small .table tfoot th.cell-total, .table-four.table-four--small .table-edit .table tbody th.cell-total, .table-edit .table-four.table-four--small .table tbody th.cell-total {
        text-transform: uppercase; }
        .table-four.table-four--small .table thead th.cell-total:first-child, .table-four.table-four--small .table-edit .table tfoot th.cell-total:first-child, .table-edit .table-four.table-four--small .table tfoot th.cell-total:first-child, .table-four.table-four--small .table-edit .table tbody th.cell-total:first-child, .table-edit .table-four.table-four--small .table tbody th.cell-total:first-child {
          border-right: 5px solid #fff; }
        .table-four.table-four--small .table thead th.cell-total:last-child, .table-four.table-four--small .table-edit .table tfoot th.cell-total:last-child, .table-edit .table-four.table-four--small .table tfoot th.cell-total:last-child, .table-four.table-four--small .table-edit .table tbody th.cell-total:last-child, .table-edit .table-four.table-four--small .table tbody th.cell-total:last-child {
          border-left: 5px solid #fff; }
        .table-four.table-four--small .table thead th.cell-total span, .table-four.table-four--small .table-edit .table tfoot th.cell-total span, .table-edit .table-four.table-four--small .table tfoot th.cell-total span, .table-four.table-four--small .table-edit .table tbody th.cell-total span, .table-edit .table-four.table-four--small .table tbody th.cell-total span {
          display: block;
          line-height: 1.1;
          text-transform: capitalize; }
  .table-four.table-four--small .table tbody td {
    border: 0; }
    .table-four.table-four--small .table tbody td:first-child {
      padding-left: 0; }
    .table-four.table-four--small .table tbody td:last-child {
      padding-right: 0; }
    .table-four.table-four--small .table tbody td .total {
      height: 33px;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: flex-end;
              justify-content: flex-end;
      -webkit-align-items: flex-end;
              align-items: flex-end;
      padding-right: 0.3em;
      font-family: "Roboto", sans-serif;
      font-size: 0.750em;
      font-weight: 800;
      background-color: #A2BFD0; }
      .table-four.table-four--small .table tbody td .total .small {
        display: inline-block;
        position: relative;
        bottom: 0.5em;
        font-size: 0.55em; }

.table-edit.second th.title-cell, .table-edit.second th.notes-cell,
.table-edit.second td.title-cell,
.table-edit.second td.notes-cell {
  padding-bottom: 0;
  border-bottom: 0;
  font-family: "Roboto", sans-serif;
  font-size: 0.8rem;
  font-weight: 800;
  line-height: 1.5;
  color: #3D708D; }

.table-edit.second th.notes-cell,
.table-edit.second td.notes-cell {
  font-weight: 400;
  color: #2D3B41; }

.table-edit.second thead th, .table-edit.second .table tfoot th, .table-edit.second .table tbody th {
  border-top: 0;
  font-weight: 600; }

.no-attached,
.yes-attached {
  display: inline-block;
  vertical-align: middle; }
  .no-attached::before,
  .yes-attached::before {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    margin-right: 0.5rem; }

.no-attached {
  margin-left: 0.3rem; }
  .no-attached::before {
    content: '\F057';
    color: #2D3B41; }

.yes-attached {
  margin-right: 0.3rem; }
  .yes-attached::before {
    content: '\F0C6';
    color: #E70C21; }

.block-table .block-table__body {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  padding-top: 1rem;
  padding-bottom: 1rem; }

.table-main-list .table {
  font-size: 0.8rem; }
  .table-main-list .table thead th, .table-main-list .table-edit .table tfoot th, .table-edit .table-main-list .table tfoot th, .table-main-list .table-edit .table tbody th, .table-edit .table-main-list .table tbody th,
  .table-main-list .table thead td,
  .table-main-list .table-edit .table tfoot td,
  .table-edit .table-main-list .table tfoot td,
  .table-main-list .table-edit .table tbody td,
  .table-edit .table-main-list .table tbody td,
  .table-main-list .table tbody th,
  .table-main-list .table tbody td {
    min-width: 60px;
    vertical-align: middle; }
    @media only screen and (min-width: 1248px) {
      .table-main-list .table thead th, .table-main-list .table-edit .table tfoot th, .table-edit .table-main-list .table tfoot th, .table-main-list .table-edit .table tbody th, .table-edit .table-main-list .table tbody th,
      .table-main-list .table thead td,
      .table-main-list .table-edit .table tfoot td,
      .table-edit .table-main-list .table tfoot td,
      .table-main-list .table-edit .table tbody td,
      .table-edit .table-main-list .table tbody td,
      .table-main-list .table tbody th,
      .table-main-list .table tbody td {
        white-space: nowrap; } }
    .table-main-list .table thead th div div, .table-main-list .table-edit .table tfoot th div div, .table-edit .table-main-list .table tfoot th div div, .table-main-list .table-edit .table tbody th div div, .table-edit .table-main-list .table tbody th div div,
    .table-main-list .table thead td div div,
    .table-main-list .table-edit .table tfoot td div div,
    .table-edit .table-main-list .table tfoot td div div,
    .table-main-list .table-edit .table tbody td div div,
    .table-edit .table-main-list .table tbody td div div,
    .table-main-list .table tbody th div div,
    .table-main-list .table tbody td div div {
      padding-left: 2px;
      padding-right: 2px; }

.table-second .table.drop-work-unit, .table-third .table.drop-work-unit {
  width: auto;
  margin-bottom: 0.1rem; }
  .table-second .table.drop-work-unit thead th.th-bottom__item, .table-third .table.drop-work-unit thead th.th-bottom__item, .table-second .table-edit .table.drop-work-unit tfoot th.th-bottom__item, .table-edit .table-second .table.drop-work-unit tfoot th.th-bottom__item, .table-third .table-edit .table.drop-work-unit tfoot th.th-bottom__item, .table-edit .table-third .table.drop-work-unit tfoot th.th-bottom__item, .table-second .table-edit .table.drop-work-unit tbody th.th-bottom__item, .table-edit .table-second .table.drop-work-unit tbody th.th-bottom__item, .table-third .table-edit .table.drop-work-unit tbody th.th-bottom__item, .table-edit .table-third .table.drop-work-unit tbody th.th-bottom__item, .table-second .table.drop-work-unit thead th.day, .table-second .table-edit .table.drop-work-unit tfoot th.day, .table-edit .table-second .table.drop-work-unit tfoot th.day, .table-second .table-edit .table.drop-work-unit tbody th.day, .table-edit .table-second .table.drop-work-unit tbody th.day, .table-third .table.drop-work-unit thead th.day, .table-third .table-edit .table.drop-work-unit tfoot th.day, .table-edit .table-third .table.drop-work-unit tfoot th.day, .table-third .table-edit .table.drop-work-unit tbody th.day, .table-edit .table-third .table.drop-work-unit tbody th.day,
  .table-second .table.drop-work-unit thead td.th-bottom__item,
  .table-third .table.drop-work-unit thead td.th-bottom__item,
  .table-second .table-edit .table.drop-work-unit tfoot td.th-bottom__item,
  .table-edit .table-second .table.drop-work-unit tfoot td.th-bottom__item,
  .table-third .table-edit .table.drop-work-unit tfoot td.th-bottom__item,
  .table-edit .table-third .table.drop-work-unit tfoot td.th-bottom__item,
  .table-second .table-edit .table.drop-work-unit tbody td.th-bottom__item,
  .table-edit .table-second .table.drop-work-unit tbody td.th-bottom__item,
  .table-third .table-edit .table.drop-work-unit tbody td.th-bottom__item,
  .table-edit .table-third .table.drop-work-unit tbody td.th-bottom__item,
  .table-second .table.drop-work-unit thead td.day,
  .table-second .table-edit .table.drop-work-unit tfoot td.day,
  .table-edit .table-second .table.drop-work-unit tfoot td.day,
  .table-second .table-edit .table.drop-work-unit tbody td.day,
  .table-edit .table-second .table.drop-work-unit tbody td.day,
  .table-third .table.drop-work-unit thead td.day,
  .table-third .table-edit .table.drop-work-unit tfoot td.day,
  .table-edit .table-third .table.drop-work-unit tfoot td.day,
  .table-third .table-edit .table.drop-work-unit tbody td.day,
  .table-edit .table-third .table.drop-work-unit tbody td.day {
    vertical-align: middle; }
  .table-second .table.drop-work-unit tbody th, .table-third .table.drop-work-unit tbody th,
  .table-second .table.drop-work-unit tbody td,
  .table-third .table.drop-work-unit tbody td {
    border-bottom: 0; }
    .table-second .table.drop-work-unit tbody th.th-bottom__item, .table-third .table.drop-work-unit tbody th.th-bottom__item, .table-second .table.drop-work-unit tbody th.day, .table-third .table.drop-work-unit tbody th.day,
    .table-second .table.drop-work-unit tbody td.th-bottom__item,
    .table-third .table.drop-work-unit tbody td.th-bottom__item,
    .table-second .table.drop-work-unit tbody td.day,
    .table-third .table.drop-work-unit tbody td.day {
      vertical-align: middle; }
  
  .table-second .table.drop-work-unit tbody td,
  .table-third .table.drop-work-unit tbody td {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 0; }
    .table-second .table.drop-work-unit tbody td table, .table-third .table.drop-work-unit tbody td table {
      width: auto;
      height: 100%;
      border: 0;
      box-shadow: none; }
      .table-second .table.drop-work-unit tbody td table td, .table-third .table.drop-work-unit tbody td table td {
        width: auto;
        height: 100%;
        border-top: 1px solid #f1f1f1;
        border-left: 1px solid #f1f1f1;
        border-right: 1px solid #f1f1f1;
        border-bottom: 1px solid #f1f1f1; }

.list-quickview-button, .remove,
.list-edit-button,
.edit {
  width: 20px;
  height: 20px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  border-radius: 4px;
  cursor: pointer; }
  .list-quickview-button .fas, .remove .fas,
  .list-quickview-button .far,
  .remove .far,
  .list-quickview-button .fa,
  .remove .fa,
  .list-edit-button .fas,
  .edit .fas,
  .list-edit-button .far,
  .edit .far,
  .list-edit-button .fa,
  .edit .fa {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-size: 12px;
    color: #fff; }
  .list-quickview-button:hover, .remove:hover,
  .list-edit-button:hover,
  .edit:hover {
    opacity: .85; }

.list-quickview-button, .remove {
  margin-right: 0.4rem;
  background-color: #6299B9; }


.list-edit-button,
.edit {
  background-color: #A2BFD0; }

.edit {
  margin-right: 0.2rem; }

.remove {
  margin-right: 0;
  margin-left: 0.2rem;
  background-color: #BC212C; }

.inactive {
  background-color: #E9ECEF; }

.finSemana {
  background-color: #e1e5e9; }

.no-cabe-derecha {
  border-right: 5px solid #BC212C; }

.no-cabe-izquierda {
  border-left: 5px solid #BC212C; }

.icon-and-text .icon-and-text__item {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }
  .icon-and-text .icon-and-text__item .icon-and-text__item__text {
    margin-left: 0.4rem; }
  .icon-and-text .icon-and-text__item .fas,
  .icon-and-text .icon-and-text__item .fa,
  .icon-and-text .icon-and-text__item .far {
    font-size: 1.2em; }
    .icon-and-text .icon-and-text__item .fas.fa-check-circle,
    .icon-and-text .icon-and-text__item .fa.fa-check-circle,
    .icon-and-text .icon-and-text__item .far.fa-check-circle {
      color: #6C757D; }
    .icon-and-text .icon-and-text__item .fas.fa-times-circle,
    .icon-and-text .icon-and-text__item .fa.fa-times-circle,
    .icon-and-text .icon-and-text__item .far.fa-times-circle {
      color: #E51227; }

.list-component {
  position: relative; }

.table-responsive.list-component {
  min-height: 200px; }

table.drop-work-unit .label-days > tr td {
  padding: 0;
  border: 0;
  border-right: 1px solid #D3D3D3; }
  table.drop-work-unit .label-days > tr td:last-child {
    border-right: 0; }

table.drop-work-unit tr td.day {
  padding: 0;
  border: 0;
  border-right: 1px solid #D3D3D3; }
  table.drop-work-unit tr td.day:last-child {
    border-right: 0; }
  table.drop-work-unit tr td.day .th-bottom .day {
    font-size: 0.588rem;
    padding-left: 2px; }

.drop-work-unit td {
  border: 1px solid black; }

.drop-work-unit td:first-child {
  border-left: 0; }

.drop-work-unit td:last-child {
  border-right: 0; }

.drop-work-unit td.week {
  border-left: 1px solid black;
  border-right: none; }

.table-second .table, .table-third .table {
  width: auto;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2); }
  .table-second .table thead th, .table-third .table thead th, .table-second .table-edit .table tfoot th, .table-edit .table-second .table tfoot th, .table-third .table-edit .table tfoot th, .table-edit .table-third .table tfoot th, .table-second .table-edit .table tbody th, .table-edit .table-second .table tbody th, .table-third .table-edit .table tbody th, .table-edit .table-third .table tbody th {
    width: auto;
    padding: 0 0.571rem;
    border-bottom: 1px solid #212529;
    border-right: 1px solid #212529; }
    .table-second .table thead th:last-child, .table-third .table thead th:last-child, .table-second .table-edit .table tfoot th:last-child, .table-edit .table-second .table tfoot th:last-child, .table-third .table-edit .table tfoot th:last-child, .table-edit .table-third .table tfoot th:last-child, .table-second .table-edit .table tbody th:last-child, .table-edit .table-second .table tbody th:last-child, .table-third .table-edit .table tbody th:last-child, .table-edit .table-third .table tbody th:last-child {
      border-right: 0; }
  .table-second .table .th-top, .table-third .table .th-top {
    margin-bottom: 0.857em;
    font-family: "Raleway", sans-serif;
    font-size: 0.8rem;
    color: #212529;
    text-align: left; }
  .table-second .table .th-cliente, .table-third .table .th-cliente {
    width: 205.2px; }
  .table-second .table .th-top-cliente, .table-third .table .th-top-cliente {
    width: 209px; }
  .table-second .table .display-flex-calendar, .table-third .table .display-flex-calendar {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between; }
  .table-second .table .th-bottom, .table-third .table .th-bottom {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding: 0;
    margin: 0;
    list-style-type: none; }
    .table-second .table .th-bottom.num, .table-third .table .th-bottom.num {
      width: 100%;
      margin: 0 auto; }
      .table-second .table .th-bottom.num .fa-angle-left, .table-third .table .th-bottom.num .fa-angle-left {
        display: unset;
        visibility: visible; }
      .table-second .table .th-bottom.num .th-bottom-client, .table-third .table .th-bottom.num .th-bottom-client {
        width: 36.9px !important;
        display: -webkit-flex;
        display: flex; }
      .table-second .table .th-bottom.num .th-bottom__item, .table-third .table .th-bottom.num .th-bottom__item, .table-second .table .th-bottom.num .day, .table-third .table .th-bottom.num .day {
        border-right: 1px solid #212529;
        text-align: center;
        margin: 0 auto; }
        .table-second .table .th-bottom.num .th-bottom__item:last-child, .table-third .table .th-bottom.num .th-bottom__item:last-child, .table-second .table .th-bottom.num .day:last-child, .table-third .table .th-bottom.num .day:last-child {
          border-right: 0; }
        .table-second .table .th-bottom.num .th-bottom__item span, .table-third .table .th-bottom.num .th-bottom__item span, .table-second .table .th-bottom.num .day span, .table-third .table .th-bottom.num .day span {
          display: inline-block; }
    .table-second .table .th-bottom.text .th-bottom__item, .table-third .table .th-bottom.text .th-bottom__item, .table-second .table .th-bottom.text .day, .table-third .table .th-bottom.text .day {
      max-width: 110px; }
  .table-second .table .th-bottom__item, .table-third .table .th-bottom__item, .table-second .table .day, .table-third .table .day {
    font-family: "Raleway", sans-serif;
    font-size: 0.688rem;
    color: #212529;
    max-width: 100% !important; }
    .table-second .table .th-bottom__item .day, .table-third .table .th-bottom__item .day, .table-second .table .day .day, .table-third .table .day .day {
      margin-left: 2px;
      font-size: 0.75rem;
      color: #D3D3D3;
      text-transform: uppercase; }
  .table-second .table .day b, .table-third .table .day b {
    margin-right: 0.2rem; }
  .table-second .table tbody th, .table-third .table tbody th {
    padding: 0.25em 0.5em;
    line-height: 1.3;
    border-bottom: 2px solid #A2BFD0; }
    .table-second .table tbody th .shop-num, .table-third .table tbody th .shop-num {
      display: block; }
    .table-second .table tbody th .th-bottom .th-bottom__item, .table-third .table tbody th .th-bottom .th-bottom__item, .table-second .table tbody th .th-bottom .day, .table-third .table tbody th .th-bottom .day {
      font-family: "Roboto", sans-serif;
      color: #576267; }
    .table-second .table tbody th .table-sublist, .table-third .table tbody th .table-sublist {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: flex-end;
              justify-content: flex-end;
      padding: 0;
      list-style-type: none; }
      .table-second .table tbody th .table-sublist li, .table-third .table tbody th .table-sublist li {
        width: 12px;
        height: 33px;
        position: relative;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: flex-end;
                align-items: flex-end;
        margin-right: 3px;
        background-color: #A2BFD0; }
        .table-second .table tbody th .table-sublist li:last-child, .table-third .table tbody th .table-sublist li:last-child {
          margin-right: 0; }
        .table-second .table tbody th .table-sublist li.no-active, .table-third .table tbody th .table-sublist li.no-active {
          opacity: 0.5;
          pointer-events: none; }
        .table-second .table tbody th .table-sublist li a, .table-third .table tbody th .table-sublist li a {
          text-decoration: none;
          display: block;
          margin-bottom: 0.3em;
          font-size: 0.75em;
          font-weight: 800;
          color: #2D3B41; }
          .table-second .table tbody th .table-sublist li a .small, .table-third .table tbody th .table-sublist li a .small {
            font-size: 0.65em; }
  .table-second .table tbody td, .table-third .table tbody td {
    width: auto;
    height: 33px;
    padding: 0;
    vertical-align: middle;
    border-left: 1px solid #D3D3D3;
    border-bottom: 1px solid #D3D3D3; }
    .table-second .table tbody td .th-bottom.num, .table-third .table tbody td .th-bottom.num {
      height: 100%;
      overflow: hidden; }
      .table-second .table tbody td .th-bottom.num .th-bottom__item, .table-third .table tbody td .th-bottom.num .th-bottom__item, .table-second .table tbody td .th-bottom.num .day, .table-third .table tbody td .th-bottom.num .day {
        height: 100%;
        border-right: 1px solid #D3D3D3; }
        .table-second .table tbody td .th-bottom.num .th-bottom__item:last-child, .table-third .table tbody td .th-bottom.num .th-bottom__item:last-child, .table-second .table tbody td .th-bottom.num .day:last-child, .table-third .table tbody td .th-bottom.num .day:last-child {
          border-right: 0; }
        .table-second .table tbody td .th-bottom.num .th-bottom__item .left, .table-third .table tbody td .th-bottom.num .th-bottom__item .left, .table-second .table tbody td .th-bottom.num .day .left, .table-third .table tbody td .th-bottom.num .day .left,
        .table-second .table tbody td .th-bottom.num .th-bottom__item .right,
        .table-third .table tbody td .th-bottom.num .th-bottom__item .right,
        .table-second .table tbody td .th-bottom.num .day .right,
        .table-third .table tbody td .th-bottom.num .day .right {
          width: calc(50% - 2px);
          height: 100%;
          position: relative;
          display: inline-block;
          margin: 1px; }
        .table-second .table tbody td .th-bottom.num .th-bottom__item .left::after, .table-third .table tbody td .th-bottom.num .th-bottom__item .left::after, .table-second .table tbody td .th-bottom.num .day .left::after, .table-third .table tbody td .th-bottom.num .day .left::after {
          content: '';
          width: 1px;
          height: 15px;
          position: absolute;
          right: 0;
          bottom: 0;
          background-color: #D3D3D3; }
        .table-second .table tbody td .th-bottom.num .th-bottom__item.no-work, .table-third .table tbody td .th-bottom.num .th-bottom__item.no-work, .table-second .table tbody td .th-bottom.num .no-work.day, .table-third .table tbody td .th-bottom.num .no-work.day {
          background-color: #e0e0e0;
          pointer-events: none; }
  .table-second .table tbody th.th-bg, .table-third .table tbody th.th-bg {
    max-width: 70px;
    padding-top: 0.8em;
    padding-bottom: 0.8em;
    font-family: "Roboto", sans-serif;
    font-size: 1em;
    font-weight: 400;
    color: #fff;
    background-color: #2D3B41; }

.table-second.second .table th .th-bottom.text .th-bottom__item, .second.table-third .table th .th-bottom.text .th-bottom__item, .table-second.second .table th .th-bottom.text .day, .second.table-third .table th .th-bottom.text .day {
  font-size: 0.88em; }

.table-second.second .table tbody tr.no-work td, .second.table-third .table tbody tr.no-work td {
  position: relative; }
  .table-second.second .table tbody tr.no-work td::before, .second.table-third .table tbody tr.no-work td::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.5);
    pointer-events: none; }

.table-second.second .table tbody th, .second.table-third .table tbody th {
  vertical-align: middle; }

.table-third .table {
  height: 155px; }
  .table-third .table tbody th {
    width: auto;
    min-height: 135px;
    position: relative;
    padding: 0.857em 0.571em;
    background-color: #2D3B41;
    border-top: 0;
    border-bottom: 0; }
    .table-third .table tbody th .th-top,
    .table-third .table tbody th .th-bottom {
      font-family: "Roboto", sans-serif;
      font-size: 0.8rem;
      font-weight: 400;
      text-align: left;
      color: #fff; }
      .table-third .table tbody th .th-top span,
      .table-third .table tbody th .th-bottom span {
        font-size: inherit;
        color: inherit; }
    .table-third .table tbody th .th-bottom {
      height: 35px;
      line-height: 35px;
      position: absolute;
      right: 0.857em;
      bottom: 0;
      text-align: right; }
  .table-third .table tbody td {
    height: 100%;
    padding: 0; }
    .table-third .table tbody td .th-bottom {
      line-height: 35px;
      position: relative;
      text-align: right; }

.contenedor-client {
  height: 117px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-end;
          justify-content: flex-end; }

.hour-client {
  line-height: 16px; }

.calc-hour, .calc-hour1, .calc-hour2, .calc-hour3, .calc-hour4, .calc-hour5, .calc-hour6, .calc-hour7, .calc-hour8, .calc-hour9, .calc-hour10 {
  -webkit-justify-content: center !important;
          justify-content: center !important;
  margin: 0 auto !important;
  width: 50%; }

.calc-hour1 {
  background: #A2BFD0;
  height: 10%; }

.calc-hour2 {
  background: #A2BFD0;
  height: 20%; }

.calc-hour3 {
  background: #A2BFD0;
  height: 30%; }

.calc-hour4 {
  background: #A2BFD0;
  height: 40%; }

.calc-hour5 {
  background: #A2BFD0;
  height: 50%; }

.calc-hour6 {
  background: #A2BFD0;
  height: 60%; }

.calc-hour7 {
  background: #A2BFD0;
  height: 70%; }

.calc-hour8 {
  background: #A2BFD0;
  height: 80%; }

.calc-hour9 {
  background: #A2BFD0;
  height: 90%; }

.calc-hour10 {
  background: #A2BFD0;
  height: 100%; }

.weekDay {
  border-right: 2px solid lightgray !important; }

.weekDay td.drop-hours:last-child {
  border-right: 0 !important; }

.weekDay td.drop-hours:first-child {
  border-left: 0 !important; }

.th-name-tecnico {
  border-right: 1px solid black; }

td.week.with-1-days {
  padding-left: 6px;
  padding-right: 0px; }

.search-block {
  height: 2.375rem;
  font-size: 0.85rem; }
  @media only screen and (min-width: 768px) {
    .search-block {
      font-size: 1rem; } }
  .search-block input {
    height: 100%;
    display: block;
    padding: 0 1em;
    border-right: 0;
    font-family: "Roboto", sans-serif;
    font-size: 0.875em;
    font-weight: 400;
    color: #6C757D; }
  .search-block button.btn {
    border-left: 0; }

.contacts-num,
p.contacts-num {
  height: 100%;
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-size: 1em;
  font-weight: 400;
  color: #2D3B41; }
  .contacts-num span,
  p.contacts-num span {
    font-weight: 600; }

.pagination-bottom .pagination-div {
  margin-left: 0.8rem; }

.alertsticky, .alert-danger,
div.alertsticky,
div.alert-danger {
  width: 100%;
  max-width: 800px;
  height: auto;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin: 0;
  border-radius: 4px;
  background-color: #f8d7da;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.44); }
  @media only screen and (min-width: 768px) {
    .alertsticky, .alert-danger,
    div.alertsticky,
    div.alert-danger {
      width: calc(100% - 315px);
      left: 300px; } }
  .alertsticky div, .alert-danger div,
  div.alertsticky div,
  div.alert-danger div {
    font-family: "Roboto", sans-serif;
    font-size: 0.875em;
    font-weight: 400;
    line-height: 1.5;
    color: #721C24; }

@media only screen and (min-width: 768px) {
  .sidebar-minimized .alertsticky, .sidebar-minimized .alert-danger {
    width: calc(100% - 130px);
    left: 130px; } }

/**cuando sidebar está minimizado se muestra el nombre de cada sección**/
.sidebar-minimized .nav-item__name, .sidebar-minimized .nav-item:hover .nav-item--dropdown,
.sidebar-minimized .nav-item--dropdown {
  -webkit-transform: translate(-220px, 0);
  transform: translate(-220px, 0);
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease; }

.sidebar-minimized .nav-item__name, .sidebar-minimized .nav-item:hover .nav-item--dropdown {
  display: block; }

.sidebar-minimized .nav-item:hover .nav-item__name, .sidebar-minimized .nav-item:hover .nav-item--dropdown {
  width: auto;
  min-width: 100px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  padding: 0 0.5rem;
  border-left: 1px solid #fff;
  font-weight: 400;
  color: #fff;
  background-color: #6C757D;
  -webkit-transform: translate(56px, 0);
  transform: translate(56px, 0); }
  .sidebar-minimized .nav-item:hover .nav-item__name.master, .sidebar-minimized .nav-item:hover .master.nav-item--dropdown {
    min-width: 220px; }

.sidebar-minimized .nav-item:hover .nav-item--dropdown {
  min-width: 220px;
  top: 52px;
  padding: 0; }
  .sidebar-minimized .nav-item:hover .nav-item--dropdown .nav-item--dropdown__item a {
    padding-left: 0.6rem;
    padding-right: 0.6rem; }

.sidebar-minimized .fa-angle-up,
.sidebar-minimized .fa-angle-left {
  visibility: hidden;
  display: none; }

.c-tooltip {
  width: 40%;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 0.625rem;
  z-index: 4;
  text-align: right; }
  .c-tooltip__content {
    opacity: 0;
    pointer-events: none;
    width: 100%;
    max-width: 400px;
    position: absolute;
    top: 1.6rem;
    right: 0;
    padding: 0.5rem;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    border: 1px solid #576267;
    border-radius: 4px;
    background-color: white;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease; }
    .c-tooltip__content-item {
      -webkit-flex: 0 1 45%;
              flex: 0 1 45%;
      min-width: 150px;
      padding: 0.5rem;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center; }
      .c-tooltip__content-item-text {
        margin: 0;
        font-family: "Roboto", sans-serif;
        font-size: 0.7rem;
        font-weight: 400;
        text-align: left;
        text-transform: capitalize;
        color: #576267; }
      .c-tooltip__content-item-state {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        padding-left: 0.5rem; }
        .c-tooltip__content-item-state.no-ico {
          padding-left: 0.2rem; }
      .c-tooltip__content-item-color {
        width: 15px;
        height: 15px;
        position: relative;
        margin-left: 1.25rem;
        margin-right: 0.8rem;
        border: 1px solid #eee;
        border-radius: 3px; }
        .c-tooltip__content-item-color.user-state-check::before, .c-tooltip__content-item-color.user-state::before {
          font-family: 'Font Awesome 5 Free';
          font-weight: 900;
          font-size: 13px;
          color: #212529;
          position: absolute;
          top: 50%;
          -webkit-transform: translateY(-50%);
                  transform: translateY(-50%);
          left: -1.25rem; }
        .c-tooltip__content-item-color.user-state::before {
          content: '\F007'; }
        .c-tooltip__content-item-color.user-state-check::before {
          content: '\F4FC'; }
        .c-tooltip__content-item-color.color-prevent {
          background-color: #A2BFD0; }
        .c-tooltip__content-item-color.color-program {
          background-color: #B197DC; }
        .c-tooltip__content-item-color.color-corrective {
          background-color: #EA9595; }
        .c-tooltip__content-item-color.color-events {
          background-color: #FFBF8A; }
        .c-tooltip__content-item-color.color-prevent--lines {
          background: -webkit-repeating-linear-gradient(135deg, white, white 3px, #A2BFD0 3px, #A2BFD0 6px);
          background: repeating-linear-gradient(-45deg, white, white 3px, #A2BFD0 3px, #A2BFD0 6px); }
        .c-tooltip__content-item-color.color-program--lines {
          background: -webkit-repeating-linear-gradient(135deg, white, white 3px, #B197DC 3px, #B197DC 6px);
          background: repeating-linear-gradient(-45deg, white, white 3px, #B197DC 3px, #B197DC 6px); }
        .c-tooltip__content-item-color.color-corrective--lines {
          background: -webkit-repeating-linear-gradient(135deg, white, white 3px, #EA9595 3px, #EA9595 6px);
          background: repeating-linear-gradient(-45deg, white, white 3px, #EA9595 3px, #EA9595 6px); }
        .c-tooltip__content-item-color.color-events--lines {
          background: -webkit-repeating-linear-gradient(135deg, white, white 3px, #FFBF8A 3px, #FFBF8A 6px);
          background: repeating-linear-gradient(-45deg, white, white 3px, #FFBF8A 3px, #FFBF8A 6px); }
  .c-tooltip__ico {
    padding: 0;
    border: 0;
    outline: 0;
    background-color: transparent;
    font-size: 1.1rem;
    color: #2D3B41; }
    .c-tooltip__ico .fas,
    .c-tooltip__ico .fa,
    .c-tooltip__ico .far {
      margin: 0;
      padding: 0;
      font-size: inherit;
      color: inherit; }
    .c-tooltip__ico:focus {
      outline: 1px dotted #fff; }
    .c-tooltip__ico:hover {
      -webkit-transition: all 0.9s ease;
      transition: all 0.9s ease; }
      .c-tooltip__ico:hover + .c-tooltip__content {
        opacity: 1;
        pointer-events: all; }

.workorderselect {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: transparent;
  z-index: 2000; }

.c-filters-shared__col {
  margin-bottom: 2rem; }
  .c-filters-shared__col:last-child {
    margin-bottom: 0; }
  .c-filters-shared__col__title, h1.c-filters-shared__col-title,
  .modal-push [class*="col-title"] {
    margin: 0 0 0.8rem 0;
    padding: 0;
    font-family: "Roboto", sans-serif;
    font-size: 0.8rem;
    font-weight: 600;
    line-height: 1.5;
    text-align: left;
    text-transform: capitalize;
    color: #212529; }

.c-filters-shared-list {
  padding: 0;
  list-style-type: none; }
  .c-filters-shared-list__item {
    margin-bottom: 0.4rem;
    font-family: "Roboto", sans-serif;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-transform: none;
    text-decoration: none;
    color: #2D3B41; }
    .c-filters-shared-list__item:last-child {
      margin-bottom: 0; }
    .c-filters-shared-list__item-link {
      display: inline-block;
      vertical-align: middle;
      font-family: inherit;
      font-size: inherit;
      font-weight: inherit;
      letter-spacing: inherit;
      cursor: pointer; }
      .c-filters-shared-list__item-link:hover {
        text-decoration: underline; }
      .c-filters-shared-list__item-link:focus {
        outline: 1px dotted #212529; }
    .c-filters-shared-list__item .remove {
      display: inline-block;
      vertical-align: middle;
      margin-left: 0.6rem;
      margin-right: 0;
      font-size: 0.9rem;
      background-color: transparent;
      color: #E51227; }
      .c-filters-shared-list__item .remove .fas,
      .c-filters-shared-list__item .remove .fa,
      .c-filters-shared-list__item .remove .far {
        position: static;
        -webkit-transform: none;
                transform: none;
        font-size: inherit;
        color: inherit; }
      .c-filters-shared-list__item .remove:hover {
        color: #2D3B41; }

.c-modal-info-cell {
  width: 98%;
  max-width: 31.250rem;
  position: absolute;
  z-index: 999;
  padding: 0.938rem;
  background-color: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.44);
  border-radius: 4px; }
  .c-modal-info-cell .c-modal-info-cell__header {
    position: relative; }
    @media only screen and (min-width: 480px) {
      .c-modal-info-cell .c-modal-info-cell__header {
        -webkit-flex-direction: row;
                flex-direction: row; } }
    .c-modal-info-cell .c-modal-info-cell__header .c-modal-info-cell__header__left {
      width: 100%; }
    .c-modal-info-cell .c-modal-info-cell__header .c-modal-info-cell__header__right {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
              justify-content: center;
      position: absolute;
      right: 0;
      top: 0; }
      .c-modal-info-cell .c-modal-info-cell__header .c-modal-info-cell__header__right .c-modal-info-cell__square {
        width: auto;
        max-width: 5rem;
        height: 2.188rem;
        line-height: 2.188rem;
        padding: 0 0.625rem;
        margin: 0 0.438rem 0.4rem 0;
        background-color: white;
        border: 1px solid #CED4DA;
        border-radius: 4px;
        font-family: "Roboto", sans-serif;
        font-size: 0.875rem;
        font-weight: 400;
        color: #2D3B41; }
        .c-modal-info-cell .c-modal-info-cell__header .c-modal-info-cell__header__right .c-modal-info-cell__square:last-child {
          margin-right: 0; }
        .c-modal-info-cell .c-modal-info-cell__header .c-modal-info-cell__header__right .c-modal-info-cell__square.user .fa-user-check {
          color: #3D708D; }
        .c-modal-info-cell .c-modal-info-cell__header .c-modal-info-cell__header__right .c-modal-info-cell__square.user .fa-user {
          color: #A2BFD0; }
  .c-modal-info-cell .c-modal-info__cell__body {
    margin-top: 0; }
    .c-modal-info-cell .c-modal-info__cell__body .c-modal-info-cell__box {
      border: 1px solid #3D708D;
      border-radius: 4px;
      padding: 0.4rem 1.8rem 0.4rem 0.4rem;
      margin-bottom: 0.4375rem; }
  .c-modal-info-cell::before, .c-modal-info-cell::after {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    border-style: solid; }
  .c-modal-info-cell::before {
    content: "";
    top: -20px;
    left: 5px;
    z-index: 9999;
    border-width: 0 15px 22px 15px;
    border-color: transparent transparent #fff transparent; }
  .c-modal-info-cell::after {
    top: -23px;
    left: 5px;
    border-width: 0 16px 24px 16px;
    border-color: transparent transparent rgba(0, 0, 0, 0.08) transparent;
    z-index: 9998; }
  .c-modal-info-cell.flecha-arriba.flecha-derecha::before, .c-modal-info-cell.flecha-arriba.flecha-derecha::after {
    content: '';
    left: auto;
    right: 5px; }
  .c-modal-info-cell.flecha-debajo.flecha-izquierda::before, arrow top abajo a la derecha .c-modal-info-cell.flecha-debajo.flecha-derecha::before {
    content: '';
    top: auto;
    bottom: -20px;
    border-width: 22px 15px 0 15px;
    border-color: #fff  transparent transparent  transparent; }
  .c-modal-info-cell.flecha-debajo.flecha-izquierda::after, arrow top abajo a la derecha .c-modal-info-cell.flecha-debajo.flecha-derecha::after {
    top: auto;
    bottom: -24px;
    border-width: 24px 16px 0 16px;
    border-color: rgba(0, 0, 0, 0.08) transparent transparent transparent;
    z-index: 9998; }
  arrow top abajo a la derecha .c-modal-info-cell.flecha-debajo.flecha-derecha::before, arrow top abajo a la derecha .c-modal-info-cell.flecha-debajo.flecha-derecha::after {
    content: '';
    left: auto;
    right: 5px; }

.c-modal-info__cell__body {
  margin-top: 0; }
  .c-modal-info__cell__body.transparent {
    opacity: .5; }
  .c-modal-info__cell__body .c-modal-info-cell__box {
    border: 1px solid #3D708D;
    border-radius: 4px;
    padding: 0.4rem 1.8rem 0.4rem 0.4rem;
    margin-bottom: 0.4375rem; }
    .c-modal-info__cell__body .c-modal-info-cell__box .c-modal-info-cell__orders {
      width: 100%;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
              justify-content: space-between;
      -webkit-flex-wrap: wrap;
              flex-wrap: wrap;
      margin: 0 auto 0.413rem auto;
      list-style-type: none;
      margin-bottom: 0;
      padding: 0; }
      .c-modal-info__cell__body .c-modal-info-cell__box .c-modal-info-cell__orders.success {
        color: #28A745; }
      .c-modal-info__cell__body .c-modal-info-cell__box .c-modal-info-cell__orders.blue {
        color: #3D708D; }
      .c-modal-info__cell__body .c-modal-info-cell__box .c-modal-info-cell__orders.warning {
        color: #BC212C; }
      .c-modal-info__cell__body .c-modal-info-cell__box .c-modal-info-cell__orders.medium {
        font-weight: 500; }
      .c-modal-info__cell__body .c-modal-info-cell__box .c-modal-info-cell__orders .c-modal-info-cell__orders__item {
        font-weight: 600;
        font-size: 0.75rem; }
      .c-modal-info__cell__body .c-modal-info-cell__box .c-modal-info-cell__orders .c-modal-info-cell__orders__item-status {
        font-weight: 600;
        font-size: 0.75rem;
        padding-right: 17px; }
    .c-modal-info__cell__body .c-modal-info-cell__box .table {
      margin-bottom: 0; }
      .c-modal-info__cell__body .c-modal-info-cell__box .table td {
        padding: 0;
        border: 0; }
  .c-modal-info__cell__body .success {
    border-color: #28A745; }
  .c-modal-info__cell__body .program {
    background-color: #eae3f6; }
  .c-modal-info__cell__body .prevent {
    background-color: #e6eef2; }
  .c-modal-info__cell__body .corrective {
    background-color: #fbeaea; }
  .c-modal-info__cell__body.clientSchedule {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: space-between;
            justify-content: space-between; }
    .c-modal-info__cell__body.clientSchedule .c-modal-info-cell__box {
      width: 50%;
      padding: 0;
      border: none;
      margin-bottom: 0; }
      .c-modal-info__cell__body.clientSchedule .c-modal-info-cell__box .c-modal-info-cell__orders {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: start;
                justify-content: start; }
        .c-modal-info__cell__body.clientSchedule .c-modal-info-cell__box .c-modal-info-cell__orders li {
          min-width: 100px;
          font-size: 0.75rem;
          color: #2D3B41;
          font-weight: 400; }
        .c-modal-info__cell__body.clientSchedule .c-modal-info-cell__box .c-modal-info-cell__orders li:nth-child(1) {
          font-style: italic;
          font-weight: 600;
          font-family: 'Roboto', sans-serif; }
        .c-modal-info__cell__body.clientSchedule .c-modal-info-cell__box .c-modal-info-cell__orders li:nth-child(2), .c-modal-info__cell__body.clientSchedule .c-modal-info-cell__box .c-modal-info-cell__orders li:nth-child(3) {
          font-weight: 600; }
        .c-modal-info__cell__body.clientSchedule .c-modal-info-cell__box .c-modal-info-cell__orders li:nth-child(2) {
          min-width: 60px; }

.c-modal-info-cell__text {
  margin: 0 0 0.313rem 0;
  font-family: "Roboto", sans-serif;
  font-size: 0.750rem;
  font-weight: 700;
  line-height: 1.5; }
  .c-modal-info-cell__text.blue {
    color: #3D708D; }
  .c-modal-info-cell__text.blue--italic {
    color: #3D708D;
    font-style: italic;
    font-weight: 300; }
  .c-modal-info-cell__text.warning {
    color: #BC212C; }
  .c-modal-info-cell__text.medium {
    font-weight: 600; }
  .c-modal-info-cell__text.regular {
    margin-top: 0.713rem;
    font-weight: 400; }
  .c-modal-info-cell__text::before {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    display: inline-block;
    margin-right: 0.438rem;
    color: #3D708D; }
  .c-modal-info-cell__text.place::before {
    content: '\F041'; }
  .c-modal-info-cell__text.comment::before {
    content: '\F27A'; }
  .c-modal-info-cell__text.technicals::before {
    content: '\F0C0'; }
  .c-modal-info-cell__text.no-technicals {
    display: inline-block;
    margin-left: 10px; }
    .c-modal-info-cell__text.no-technicals::before {
      content: '\F235'; }

footer.web-footer,
.web-footer {
  height: 1.875rem;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  z-index: 97;
  background-color: #6E757D;
  color: #fff; }
  footer.web-footer .footer-poweredby,
  .web-footer .footer-poweredby {
    width: 230px;
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    overflow-x: hidden;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    background: #000;
    font-size: 0.7rem; }
    footer.web-footer .footer-poweredby img,
    .web-footer .footer-poweredby img {
      margin-left: 5px; }
  footer.web-footer .header-toggler,
  footer.web-footer .sidebar-toggler,
  .web-footer .header-toggler,
  footer.web-footer .sidebar-toggler,
  .web-footer .sidebar-toggler {
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-align-items: center;
            align-items: center;
    background: transparent;
    border: 0;
    color: #fff;
    margin: 0 1rem;
    cursor: pointer; }
  footer.web-footer .footer-nav,
  .web-footer .footer-nav {
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-align-items: center;
            align-items: center; }
    footer.web-footer .footer-nav ul,
    .web-footer .footer-nav ul {
      display: -webkit-flex;
      display: flex;
      list-style: none;
      padding: 0;
      margin: 0; }
      footer.web-footer .footer-nav ul li,
      .web-footer .footer-nav ul li {
        margin: 0 1rem;
        font-size: 0.875rem; }
        footer.web-footer .footer-nav ul li a,
        .web-footer .footer-nav ul li a {
          font-size: 0.75rem;
          color: #fff; }

.sidebar-minimized footer.web-footer,
.sidebar-minimized .web-footer {
  width: calc(100% - 56px);
  margin-left: 56px; }

.nav-tabs {
  padding-top: 1em;
  padding-bottom: 1em;
  border-bottom: 0 !important;
  font-size: 0.85rem; }
  @media only screen and (min-width: 768px) {
    .nav-tabs {
      font-size: 1rem; } }
  .nav-tabs .nav-item .nav-link {
    width: 100%;
    position: relative;
    font-family: "Roboto", sans-serif;
    font-size: 0.875em;
    font-weight: 400;
    text-transform: capitalize;
    color: #6C757D;
    border: 0;
    background-color: transparent; }
    .nav-tabs .nav-item .nav-link.active {
      pointer-events: none;
      border: 0;
      font-weight: 600;
      color: #E51227; }
      .nav-tabs .nav-item .nav-link.active::before {
        content: '';
        width: 100%;
        height: 1px;
        position: absolute;
        bottom: 0.15em;
        left: 0;
        background-color: #E51227; }
    .nav-tabs .nav-item .nav-link:hover {
      border: 0;
      color: #3D708D; }

.modal-head .nav-tabs, .modal-push .modal-body .nav-tabs, .modal-push .modal-body-filters .nav-tabs, .modal-push .modal-footer .nav-tabs {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  border-bottom: 0; }

/* Sidebar */
.sidebar {
  /*display: inline-flex;
     flex: 0 0 230px;*/
  padding: 0;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
  background-color: #2D3B41; }
  .sidebar.sidebar--float {
    background: #fff; }

.sidebar--float {
  background-color: #fff; }

.sidebar .sidebar-nav {
  width: 230px;
  background-color: transparent;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease; }

.sidebar .nav {
  width: 100%;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  font-size: 1rem; }

.sidebar .nav-item {
  width: 100%;
  /*height: 3.250rem;*/
  height: auto;
  position: relative;
  z-index: 99;
  margin: 0;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  /*border-bottom: 1px solid #D3D3D3;*/
  white-space: nowrap; }

/*.sidebar .nav-item:first-child{
    border-top: 1px solid #D3D3D3;
}*/
.sidebar .nav-item .fa-angle-up,
.sidebar .nav-item .fa-angle-left {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: -0.25rem;
  font-size: inherit; }

.sidebar .nav-link {
  width: 100%;
  height: 3.250rem;
  display: block;
  position: relative;
  padding: 0 1.571em;
  /*22px*/
  font-family: 'Roboto', sans-serif;
  font-size: 0.875em;
  /*14px*/
  font-weight: 400;
  line-height: 3.250rem;
  color: #fff;
  text-decoration: none;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  /* border-left: 5px solid transparent;*/
  background-color: #2D3B41;
  cursor: pointer; }

.sidebar .nav-link--border {
  position: relative;
  z-index: 100;
  border-bottom: 2px solid #fff; }

.sidebar .nav-link:hover,
.sidebar .nav-link.active,
.sidebar .nav-link:hover .fa,
.sidebar .nav-link:hover .fas {
  color: #E1A23F; }

.sidebar .nav-link:hover,
.sidebar .nav-link.active,
.sidebar .nav-link.active,
.sidebar .nav-link.hover-color {
  background-color: #6C757D;
  color: #E1A23F; }

.sidebar .nav-link.active {
  text-shadow: 1px 1px #2D3B41; }

.sidebar .nav-link .fa,
.sidebar .nav-link .fas {
  width: 0.714em;
  /*10px*/
  height: 1em;
  /*14px*/
  display: inline-block;
  margin-right: 1.643em;
  /*23px*/
  font-size: 1em;
  /*14px*/
  color: #D3D3D3; }

.sidebar .nav-link.active .fa,
.sidebar .nav-link.active .fas,
.sidebar .nav-link.active .far {
  color: inherit; }

.sidebar .nav-link .badge {
  display: inline-block;
  position: absolute;
  top: calc(50% - 1px);
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 0.75rem;
  font-size: 0.65rem;
  color: #fff;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  background-color: #BC212C; }

.sidebar .nav-title {
  padding: .75rem 1rem;
  font-size: 80%;
  font-weight: 700;
  color: #e4e7ea;
  text-transform: uppercase;
  border-left: 5px solid transparent;
  border-bottom: 1px solid #5C5C5C; }

.sidebar .nav-dropdown-toggle::before {
  font-family: "Font Awesome 5 Free";
  content: "\F104";
  display: inline-block;
  vertical-align: middle;
  font-weight: 900;
  position: absolute;
  top: .75rem;
  right: 1rem;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease; }

.sidebar .nav-dropdown-items {
  max-height: 0;
  padding: 0;
  margin: 0;
  overflow-y: hidden;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease; }

.sidebar .nav-dropdown-items .nav-item {
  border-bottom: 0; }

.sidebar .nav-dropdown-items .nav-link:before {
  content: "";
  display: inline-block;
  margin-right: 0.8rem;
  width: 1.125rem; }

.sidebar .nav-dropdown.open {
  background: rgba(0, 0, 0, 0.2); }

.sidebar .nav-dropdown.open > .nav-dropdown-toggle::before {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg); }

.sidebar .nav-dropdown.open > .nav-dropdown-items {
  max-height: 1500px;
  border-top: 1px solid #5C5C5C; }

.sidebar .nav-divider {
  height: 10px; }

/*sidebar cuando está dentro de main, es decir, que no es el sidebar de navegación general*/
.main .card .sidebar {
  -webkit-flex-basis: 100%;
          flex-basis: 100%; }

.main .card .sidebar .sidebar-nav,
.sidebar-minimized .main .card .sidebar .nav {
  width: 100%; }

.sidebar-minimized .main .card .sidebar .nav-link {
  padding-left: 0.8rem;
  padding-right: 0.8rem; }

.sidebar-minimized .dropdown--slide .nav-item--dropdown {
  max-height: 100vh; }
  @media only screen and (min-width: 768px) {
    .sidebar-minimized .dropdown--slide .nav-item--dropdown {
      max-height: 66vh; } }

.title-wrapper {
  padding: 0.714em 0.929em;
  font-size: 1rem;
  border: 1px solid #e7e7e7;
  border-radius: 4px;
  background-color: #F6F6F6; }
  .title-wrapper h3.form-title,
  .title-wrapper .form-title {
    margin: 0;
    padding: 0;
    font-family: "Roboto", sans-serif;
    font-size: 0.875em;
    font-weight: 600;
    color: #707070; }
    .title-wrapper h3.form-title span,
    .title-wrapper .form-title span {
      font-weight: 500; }
  .title-wrapper .fas,
  .title-wrapper .far,
  .title-wrapper .fa {
    color: #888;
    opacity: .65; }

h3.small-title,
.small-title {
  margin-bottom: 0.4rem;
  font-family: "Roboto", sans-serif;
  font-size: 1em;
  font-weight: 500;
  color: #2D3B41; }

label,
.p-label {
  font-family: "Roboto", sans-serif;
  font-size: 0.8rem;
  font-weight: 400;
  color: #212529; }

.radiobutton, .checkbox {
  position: relative;
  font-size: 0.85rem; }
  @media only screen and (min-width: 768px) {
    .radiobutton, .checkbox {
      font-size: 1rem; } }
  .radiobutton label, .checkbox label {
    -webkit-order: 2;
            order: 2;
    margin-bottom: 0;
    padding-left: 2.129em;
    font-family: "Roboto", sans-serif;
    font-size: 0.875em;
    font-weight: 400;
    line-height: 130%;
    text-align: left;
    text-transform: capitalize;
    color: #2D3B41;
    cursor: pointer; }
    @media only screen and (min-width: 768px) {
      .radiobutton label, .checkbox label {
        font-size: 1em; } }
  .radiobutton [type="radio"], .checkbox [type="radio"],
  .radiobutton [type="checkbox"],
  .checkbox [type="checkbox"],
  .radiobutton .form-check-input,
  .checkbox .form-check-input {
    width: auto;
    height: auto; }
    .radiobutton [type="radio"]:checked, .checkbox [type="radio"]:checked, .radiobutton [type="radio"]:not(:checked), .checkbox [type="radio"]:not(:checked),
    .radiobutton [type="checkbox"]:checked,
    .checkbox [type="checkbox"]:checked,
    .radiobutton [type="checkbox"]:not(:checked),
    .checkbox [type="checkbox"]:not(:checked),
    .radiobutton .form-check-input:checked,
    .checkbox .form-check-input:checked,
    .radiobutton .form-check-input:not(:checked),
    .checkbox .form-check-input:not(:checked) {
      position: absolute;
      left: -9999px;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none; }
      .radiobutton [type="radio"]:checked + label, .checkbox [type="radio"]:checked + label, .radiobutton [type="radio"]:not(:checked) + label, .checkbox [type="radio"]:not(:checked) + label,
      .radiobutton [type="checkbox"]:checked + label,
      .checkbox [type="checkbox"]:checked + label,
      .radiobutton [type="checkbox"]:not(:checked) + label,
      .checkbox [type="checkbox"]:not(:checked) + label,
      .radiobutton .form-check-input:checked + label,
      .checkbox .form-check-input:checked + label,
      .radiobutton .form-check-input:not(:checked) + label,
      .checkbox .form-check-input:not(:checked) + label {
        position: relative; }
        .radiobutton [type="radio"]:checked + label::before, .checkbox [type="radio"]:checked + label::before, .radiobutton [type="radio"]:not(:checked) + label::before, .checkbox [type="radio"]:not(:checked) + label::before,
        .radiobutton [type="checkbox"]:checked + label::before,
        .checkbox [type="checkbox"]:checked + label::before,
        .radiobutton [type="checkbox"]:not(:checked) + label::before,
        .checkbox [type="checkbox"]:not(:checked) + label::before,
        .radiobutton .form-check-input:checked + label::before,
        .checkbox .form-check-input:checked + label::before,
        .radiobutton .form-check-input:not(:checked) + label::before,
        .checkbox .form-check-input:not(:checked) + label::before {
          content: '';
          width: 16px;
          height: 16px;
          position: absolute;
          left: 0;
          top: 0;
          border: 1px solid #007BFF;
          border-radius: 50%;
          background: white; }
        .radiobutton [type="radio"]:checked + label::after, .checkbox [type="radio"]:checked + label::after, .radiobutton [type="radio"]:not(:checked) + label::after, .checkbox [type="radio"]:not(:checked) + label::after,
        .radiobutton [type="checkbox"]:checked + label::after,
        .checkbox [type="checkbox"]:checked + label::after,
        .radiobutton [type="checkbox"]:not(:checked) + label::after,
        .checkbox [type="checkbox"]:not(:checked) + label::after,
        .radiobutton .form-check-input:checked + label::after,
        .checkbox .form-check-input:checked + label::after,
        .radiobutton .form-check-input:not(:checked) + label::after,
        .checkbox .form-check-input:not(:checked) + label::after {
          content: '';
          width: 10px;
          height: 10px;
          background: #007BFF;
          position: absolute;
          top: 3px;
          left: 3px;
          border-radius: 50%;
          -webkit-transition: all 0.2s ease;
          transition: all 0.2s ease; }
    .radiobutton [type="radio"]:not(:checked) + label::after, .checkbox [type="radio"]:not(:checked) + label::after,
    .radiobutton [type="checkbox"]:not(:checked) + label::after,
    .checkbox [type="checkbox"]:not(:checked) + label::after,
    .radiobutton .form-check-input:not(:checked) + label::after,
    .checkbox .form-check-input:not(:checked) + label::after {
      opacity: 0;
      -webkit-transform: scale(0);
              transform: scale(0); }
    .radiobutton [type="radio"]:checked + label::after, .checkbox [type="radio"]:checked + label::after,
    .radiobutton [type="checkbox"]:checked + label::after,
    .checkbox [type="checkbox"]:checked + label::after,
    .radiobutton .form-check-input:checked + label::after,
    .checkbox .form-check-input:checked + label::after {
      opacity: 1;
      -webkit-transform: scale(1);
              transform: scale(1); }

.checkbox label {
  padding-left: 3.629em; }
  .checkbox label.pl-less {
    padding-left: 1.629em;
    font-size: 0.8rem;
    line-height: 16px; }

.checkbox [type="checkbox"]:checked + label::before, .checkbox [type="checkbox"]:checked + label::after, .checkbox [type="checkbox"]:not(:checked) + label::before, .checkbox [type="checkbox"]:not(:checked) + label::after {
  border-radius: 0; }

.checkbox [type="checkbox"]:checked + label::before, .checkbox [type="checkbox"]:not(:checked) + label::before {
  width: 14px;
  height: 14px; }

.checkbox [type="checkbox"]:checked + label::after, .checkbox [type="checkbox"]:not(:checked) + label::after {
  width: 8px;
  height: 8px; }

.custom-select,
select.custom-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

input.form-control:focus, input.custom-select:focus,
select.form-control:focus,
select.custom-select:focus,
textarea.form-control:focus,
textarea.custom-select:focus,
.select-control.form-control:focus,
.select-control.custom-select:focus {
  border-color: #007bff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.custom-file .custom-file-label:focus, .custom-file .custom-file-input:focus {
  border-color: #007bff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.no-event {
  cursor: not-allowed; }
  .no-event input,
  .no-event .form-control,
  .no-event input.custom-select,
  .no-event select.custom-select,
  .no-event textarea.custom-select,
  .no-event .select-control.custom-select {
    pointer-events: none;
    border: 0; }

.form-row.with-border {
  padding: 0.875rem;
  margin-bottom: 0.875rem;
  border: 1px solid #CED4DA;
  border-radius: 4px; }

.DateIntervalInputField .control-label, .DateIntervalInputField .user .user__info .user__form .form-control, .user .user__info .user__form .DateIntervalInputField .form-control, .DateIntervalInputField .user .change-password .user__form .form-control, .user .change-password .user__form .DateIntervalInputField .form-control,
.DateIntervalInputField .user .user__info .user__form .custom-select, .user .user__info .user__form .DateIntervalInputField .custom-select,
.DateIntervalInputField .user .change-password .user__form .custom-select, .user .change-password .user__form .DateIntervalInputField .custom-select {
  width: 100%; }

.DateIntervalInputField .input-group {
  width: 100%;
  margin-bottom: 1rem; }
  @media only screen and (min-width: 992px) {
    .DateIntervalInputField .input-group {
      width: 50%;
      float: left;
      margin-bottom: 0; } }

.DateIntervalInputField > :nth-child(2)::after {
  content: "-";
  line-height: 2.5;
  padding: 0 3px;
  color: #9ca2a8; }

@media only screen and (min-width: 992px) {
  .into.DateIntervalInputField .input-group {
    width: auto; } }

.input-group-prepend .input-group-text {
  background-color: white; }

small.help-block, small.js-error, table small.js-error {
  min-height: 20px;
  display: block;
  padding-top: 2px;
  font-family: "Roboto", sans-serif;
  font-size: 0.7rem;
  font-weight: 400;
  color: #000; }

small.js-error {
  color: #E51227; }

table small.help-block, table small.js-error {
  min-height: 0;
  padding: 0;
  margin: 0; }

.max-width-input {
  max-width: 500px; }

.js-info {
  font-family: "Roboto", sans-serif;
  font-size: 0.75rem;
  line-height: 1.5; }

.table .form-group.DateInputField, .fix-form .form-group.DateInputField {
  min-width: 136px; }
  .table .form-group.DateInputField label.control-label, .table .form-group.DateInputField .user .user__info .user__form label.form-control, .user .user__info .user__form .table .form-group.DateInputField label.form-control, .table .form-group.DateInputField .user .change-password .user__form label.form-control, .user .change-password .user__form .table .form-group.DateInputField label.form-control,
  .table .form-group.DateInputField .user .user__info .user__form label.custom-select, .user .user__info .user__form .table .form-group.DateInputField label.custom-select,
  .table .form-group.DateInputField .user .change-password .user__form label.custom-select, .user .change-password .user__form .table .form-group.DateInputField label.custom-select, .fix-form .form-group.DateInputField label.control-label, .fix-form .form-group.DateInputField .user .user__info .user__form label.form-control, .user .user__info .user__form .fix-form .form-group.DateInputField label.form-control, .fix-form .form-group.DateInputField .user .change-password .user__form label.form-control, .user .change-password .user__form .fix-form .form-group.DateInputField label.form-control,
  .fix-form .form-group.DateInputField .user .user__info .user__form label.custom-select, .user .user__info .user__form .fix-form .form-group.DateInputField label.custom-select,
  .fix-form .form-group.DateInputField .user .change-password .user__form label.custom-select, .user .change-password .user__form .fix-form .form-group.DateInputField label.custom-select {
    visibility: hidden;
    display: none; }
  .table .form-group.DateInputField .input-group, .fix-form .form-group.DateInputField .input-group {
    visibility: hidden;
    display: none; }
    .table .form-group.DateInputField .input-group + .react-datepicker-wrapper, .fix-form .form-group.DateInputField .input-group + .react-datepicker-wrapper {
      position: relative; }
      .table .form-group.DateInputField .input-group + .react-datepicker-wrapper::before, .fix-form .form-group.DateInputField .input-group + .react-datepicker-wrapper::before {
        visibility: hidden;
        display: none;
        font-family: "Font Awesome 5 Free";
        content: '\F073';
        font-weight: 900;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 9;
        padding: 0.375rem 0.75rem;
        border-right: 1px solid #CED4DA;
        pointer-events: none; }
        @media only screen and (min-width: 1248px) {
          .table .form-group.DateInputField .input-group + .react-datepicker-wrapper::before, .fix-form .form-group.DateInputField .input-group + .react-datepicker-wrapper::before {
            visibility: visible;
            display: block; } }
      @media only screen and (min-width: 1248px) {
        .table .form-group.DateInputField .input-group + .react-datepicker-wrapper input.form-control, .table .form-group.DateInputField .input-group + .react-datepicker-wrapper input.custom-select, .fix-form .form-group.DateInputField .input-group + .react-datepicker-wrapper input.form-control, .fix-form .form-group.DateInputField .input-group + .react-datepicker-wrapper input.custom-select {
          text-indent: 1.8rem; } }

.card-title .fa,
.card-title .fas {
  color: #B8B8B8;
  font-weight: 400;
  margin-right: 14px; }

.user {
  width: 100%;
  padding: 0;
  margin: 0 auto;
  font-size: 0.85rem; }
  .user .user__info, .user .change-password {
    width: 100%; }
    .user .user__info .user__pic, .user .change-password .user__pic {
      width: 100%;
      max-width: 208px;
      height: 100%;
      max-height: 145px;
      position: relative;
      margin-left: auto;
      margin-right: auto;
      padding: 0.750em;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
      -moz-justify-content: center;
      -ms-justify-content: center;
      justify-content: center;
      -ms-flex-pack: center;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      border: #6C757D;
      border-radius: 4px; }
      .user .user__info .user__pic img, .user .change-password .user__pic img {
        width: auto;
        max-width: 123px;
        height: auto;
        max-height: 123px;
        display: block; }
      .user .user__info .user__pic .user__pic__edit, .user .change-password .user__pic .user__pic__edit {
        width: auto;
        max-width: 40px;
        height: auto;
        position: absolute;
        right: 0.750em;
        bottom: 0.750em;
        padding: 0;
        border: 0;
        background: none;
        cursor: pointer; }
        .user .user__info .user__pic .user__pic__edit .fa, .user .change-password .user__pic .user__pic__edit .fa,
        .user .user__info .user__pic .user__pic__edit .fas,
        .user .change-password .user__pic .user__pic__edit .fas,
        .user .user__info .user__pic .user__pic__edit .far,
        .user .change-password .user__pic .user__pic__edit .far {
          width: 100%;
          height: 100%;
          display: block;
          font-size: 2.2em;
          color: #CED4DA; }
        .user .user__info .user__pic .user__pic__edit:hover, .user .change-password .user__pic .user__pic__edit:hover {
          opacity: .85; }
    .user .user__info .user__form .form-group.no-event, .user .change-password .user__form .form-group.no-event {
      margin-bottom: 0; }
      .user .user__info .user__form .form-group.no-event input, .user .change-password .user__form .form-group.no-event input {
        border: 0; }
    .user .user__info .user__form .control-label, .user .change-password .user__form .control-label, .user .user__info .user__form .form-control, .user .change-password .user__form .form-control,
    .user .user__info .user__form .custom-select,
    .user .change-password .user__form .custom-select {
      margin-bottom: 0.688rem;
      font-family: "Roboto", sans-serif;
      font-size: 1em;
      font-weight: 400;
      text-transform: capitalize;
      line-height: 1.5;
      color: #6C757D; }
      .user .user__info .user__form .control-label.dark, .user .change-password .user__form .control-label.dark, .user .user__info .user__form .dark.form-control, .user .change-password .user__form .dark.form-control,
      .user .user__info .user__form .dark.custom-select,
      .user .change-password .user__form .dark.custom-select {
        color: #212529; }
    .user .user__info .user__form .form-control, .user .change-password .user__form .form-control, .user .user__info .user__form input.custom-select, .user .change-password .user__form input.custom-select,
    .user .user__info .user__form select.custom-select,
    .user .change-password .user__form select.custom-select,
    .user .user__info .user__form textarea.custom-select,
    .user .change-password .user__form textarea.custom-select,
    .user .user__info .user__form .select-control.custom-select,
    .user .change-password .user__form .select-control.custom-select,
    .user .user__info .user__form .custom-select,
    .user .change-password .user__form .custom-select {
      color: #212529; }
    .user .user__info .user__form .form-control, .user .change-password .user__form .form-control, .user .user__info .user__form input.custom-select, .user .change-password .user__form input.custom-select,
    .user .user__info .user__form select.custom-select,
    .user .change-password .user__form select.custom-select,
    .user .user__info .user__form textarea.custom-select,
    .user .change-password .user__form textarea.custom-select,
    .user .user__info .user__form .select-control.custom-select,
    .user .change-password .user__form .select-control.custom-select {
      margin: 0;
      padding: 0; }
  .user .change-password {
    max-width: 520px; }
    .user .change-password .user__form .form-control, .user .change-password .user__form input.custom-select,
    .user .change-password .user__form select.custom-select,
    .user .change-password .user__form textarea.custom-select,
    .user .change-password .user__form .select-control.custom-select {
      height: 2.375rem;
      padding: 0 0.5em; }
    .user .change-password .passwordNew-explication {
      font-family: "Roboto", sans-serif;
      font-size: 0.857em;
      font-weight: 400;
      line-height: 1.5;
      color: #6C757D; }

.SpinnerClipLoader.loading {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  background-color: rgba(45, 59, 65, 0.5); }

.history-container {
  font-size: 1rem;
  margin-top: 2.6em; }

.history-container-title {
  background-color: #dcdbdb;
  border-radius: 4px 4px 0 0;
  cursor: pointer;
  text-align: center !important;
  font-size: 0.9rem;
  font-weight: 600;
  text-transform: uppercase;
  padding: 10px 0px;
  margin-top: 0.5em;
  /* margin-right: 20px;
  margin-left: 30px;*/
  margin-bottom: 20px; }

.history-container-ico {
  width: 55px;
  margin: 0 auto;
  height: 50px;
  border-radius: 50% 50% 0 0;
  background-color: #dcdbdb;
  position: relative;
  margin-top: -40px;
  padding: 10px; }

.history-list-container {
  font-size: 1rem; }

.history-list-item {
  margin-right: 40px;
  margin-left: 40px;
  padding-bottom: 10px;
  padding-top: 10px;
  font-size: 0.9em;
  color: #000;
  border-bottom: 1px solid #dcdbdb; }

.history-list-item-title {
  cursor: pointer; }

.history-list-item-changes {
  color: #a7a7a7;
  text-align: right !important; }

.history-change-container {
  width: 100%;
  margin-top: 1em;
  display: -webkit-flex;
  display: flex;
  font-size: 1rem;
  background-color: transparent !important; }

.history-change {
  width: calc(50% - 1.8rem);
  margin: 0 0.9rem; }

.history-change.previous {
  background-color: #faeceb; }

.history-change.modified {
  background-color: #e4f1e6; }

.history-change-title {
  -webkit-justify-content: center !important;
          justify-content: center !important;
  font-weight: 800;
  text-transform: capitalize;
  border-top: none !important; }

.history-change-title.previous {
  color: #d63535; }

.history-change-title.modified {
  color: #51a85f; }

.history-change-item {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  -o-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  padding: 0.8em;
  border-top: 1px dotted #212529;
  font-size: 0.8em; }

.history-change-item-text {
  font-weight: 600;
  text-transform: uppercase; }

.history-change-item-text.value {
  float: right; }

.filepond--drop-label {
  font-size: 15px;
  text-align: center;
  padding: 15px;
  margin: 0 0 15px 0;
  color: #0C6CAC;
  border: 1px dashed #CED4DA;
  border-radius: 4px;
  cursor: default;
  background: #fff; }

.filepond--label-action {
  height: 2.375rem;
  line-height: 2.375rem !important;
  padding: 0 0.813rem;
  margin: 0;
  display: block;
  font-family: 'Roboto', sans-serif;
  font-size: 0.8rem !important;
  font-weight: 400;
  text-align: center;
  color: #373A3C;
  cursor: pointer;
  text-decoration: none !important;
  box-shadow: none;
  border: 1px solid #E9ECEF;
  border-radius: 0.250rem;
  background-color: #E9ECEF; }

.filepond--root .filepond--drop-label {
  min-height: 7rem !important; }

.filepond--drop-label.filepond--drop-label label {
  font-family: 'Roboto', sans-serif;
  font-size: 0.8rem;
  font-weight: 400;
  text-align: center;
  color: #6C757D; }

.fileItem {
  min-width: 110px;
  border-radius: 5px;
  background: #EEF5F9;
  border: 1px solid #EEF5F9;
  margin-bottom: 15px;
  position: relative; }

.fileItem .fileItem-header {
  min-height: 28px;
  color: #666666;
  padding: 5px 17px;
  text-align: center;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.fileItem .fileItem-header a {
  color: #666666; }

.fileItem .fileItem-header .fa {
  position: absolute;
  top: 7px;
  right: 5px;
  color: #D1322D;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 400ms ease;
  transition: all 400ms ease; }

.fileItem:hover .fileItem-header .fa {
  opacity: 1;
  visibility: visible; }

.fileItem .fileItem-header span {
  cursor: pointer; }

.fileItem .fileItem-content {
  background: #fff; }

.fileItem .fileItem-content > div {
  position: relative;
  padding-bottom: 75%;
  overflow: hidden; }

.fileItem .fileItem-content a {
  display: block;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.fileItem .fileItem-content a img {
  max-width: 100%;
  max-height: 100%; }

.fileItem .fileItem-footer {
  font-size: 0.688rem;
  padding: 0.125rem 0.1rem; }

.fileItem .fileItem-footer .size {
  float: right; }

.fileItem.readonly .fileItem-header .fa {
  display: none; }

.fileItem.readonly .fileItem-header span {
  cursor: default; }

.filepond--list.filepond--list {
  top: 8px !important; }

.block-info {
  border: 1px solid red;
  position: relative; }

.block-info-label {
  font-size: 9px;
  position: absolute;
  top: -8px;
  left: 6px;
  background-color: #fff;
  border: 1px solid red; }

.block-info-buttons {
  position: fixed;
  width: 200px;
  bottom: 0;
  background-color: white;
  color: black;
  border: 1px solid #000;
  z-index: 10000; }

