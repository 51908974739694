.filepond--drop-label{
  font-size: 15px;
  text-align: center;
  padding: 15px;
  margin: 0 0 15px 0;
  color: #0C6CAC;
  border: 1px dashed #CED4DA;
  border-radius: 4px;
  cursor: default;
  background: #fff;
}

.filepond--label-action {
  height: 2.375rem;
  line-height: 2.375rem !important;
  padding: 0 0.813rem;
  margin: 0;
  display: block;
  font-family: 'Roboto', sans-serif;
  font-size: 0.8rem !important;
  font-weight: 400;
  text-align: center;
   color: #373A3C;
  cursor: pointer;
  text-decoration: none !important;
  box-shadow: none;
  border: 1px solid #E9ECEF;
  border-radius: 0.250rem;
  background-color: #E9ECEF;
}

.filepond--root .filepond--drop-label {
  min-height: 7rem !important;
}

.filepond--drop-label.filepond--drop-label label{
    font-family: 'Roboto', sans-serif;
    font-size: 0.8rem;
    font-weight: 400;
    text-align: center;
    color: #6C757D;
}

.fileItem{
    min-width: 110px;
  border-radius: 5px;
  background: #EEF5F9;
  border:1px solid #EEF5F9;
  margin-bottom: 15px;
  position: relative;
}
.fileItem .fileItem-header{
  min-height: 28px;
  color: #666666;
  padding:5px 17px;
  text-align: center;
  position: relative;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.fileItem .fileItem-header a {
  color: #666666;
}
.fileItem .fileItem-header .fa{
  position: absolute;
  top: 7px;
  right: 5px;
  color: #D1322D;
  opacity: 0;
  visibility: hidden;
  transition: all 400ms ease;
}
.fileItem:hover .fileItem-header .fa{
  opacity: 1;
  visibility: visible;
}
.fileItem .fileItem-header span{
  cursor: pointer;
}

.fileItem .fileItem-content{
  background: #fff;
}
.fileItem .fileItem-content > div{
  position: relative;
  padding-bottom: 75%;
  overflow: hidden;
}
.fileItem .fileItem-content a{
  display: block;
  text-align: center;
  position: absolute;
  top:0;
  bottom:0;
  left:0;
  right:0;
}
.fileItem .fileItem-content a img{
  max-width: 100%;
  max-height: 100%;
}

.fileItem .fileItem-footer{
  font-size: 0.688rem;
  padding: 0.125rem 0.1rem;
}
.fileItem .fileItem-footer span{
  //float:right;
}

.fileItem .fileItem-footer .size{
  float:right;
}

.fileItem.readonly .fileItem-header .fa{
  display: none;
}
.fileItem.readonly .fileItem-header span{
  cursor: default;
}

.filepond--list.filepond--list {
  top: 8px !important;
}