//título
.title-wrapper{
    padding: 0.714em 0.929em; //10px y 13px
    font-size: $font-size;
    border: 1px solid #e7e7e7;
    border-radius: $border-radius;
    background-color: $bg-title;
    h3.form-title,
    .form-title{
        margin: 0;
        padding: 0;
        font-family: $font-base;
        font-size: 0.875em; //14px
        font-weight: 600;
        color: $title-form-color;
        span{
            font-weight: 500;
        }
    }
    .fas,
    .far,
    .fa{
        color: #888;
        opacity: .65;
    }
}
//título pequeño
h3.small-title,
.small-title{
    margin-bottom: 0.4rem;
    font-family: $font-base;
    font-size: 1em; //14px
    font-weight: 500;
    color: $font-color-dark;
}
//labels
label,
.p-label{
    font-family: $font-base;
    font-size: 0.8rem;
    font-weight: 400;
    color: $color-xdark;
}

//radiobutton
.radiobutton{
    position: relative;
    font-size: $font-size-small;
    @include mq('tablet'){
        font-size: $font-size;
    }
    label{
        order: 2;
        margin-bottom: 0;
        padding-left: 2.129em;
        font-family: $font-base;
        font-size: 0.875em; //14px
        font-weight: 400;
        line-height: 130%;
        text-align: left;
        text-transform: capitalize;
        color: $font-color;
        cursor: pointer;
        //media queries
        @include mq('tablet'){
            font-size: 1em;
        }
    }
    [type="radio"],
    [type="checkbox"],
    .form-check-input{
        width: auto;
        height: auto;
        &:checked,
        &:not(:checked){
            position: absolute;
            left: -9999px;
           @include appearance();
            + label{
                position: relative;
                &::before{
                    content: '';
                    width: 16px;
                    height: 16px;
                    position: absolute;
                    left: 0;
                    top: 0;
                    border: 1px solid $color-blue;
                    border-radius: 50%;
                    background: $bg-white;
                }
                &::after{
                    content:'';
                    width: 10px;
                    height: 10px;
                    background: $color-blue;
                    position: absolute;
                    top: 3px;
                    left: 3px;
                    border-radius: 50%;
                    transition: all 0.2s ease;
                }
            }
        }
        &:not(:checked){
            + label{
                &::after{
                    opacity: 0;
                    transform: scale(0);
                }
            }
        }
        &:checked{
            + label{
                &::after{
                    opacity: 1;
                    transform: scale(1);
                }
            }
        }
    }
}

//checkboxes
.checkbox{
    @extend .radiobutton;
    label{
        padding-left: 3.629em;
        &.pl-less{
            padding-left: 1.629em;
            font-size: 0.8rem;
            line-height: 16px; //altura checkbox
        }
    }
    [type="checkbox"]{
        &:checked,
        &:not(:checked){
            + label{
                &::before,
                &::after{
                    border-radius: 0;
                }
                &::before{
                    width: 14px;
                    height: 14px;
                }
                &::after{
                    width: 8px;
                    height: 8px;
                }
            }
        }
    }
}

//select
.custom-select,
select.custom-select{
    @include appearance();
}

//focus de inputs
input,
select,
textarea,
.select-control{
    &.form-control{
       @include focus();
    }
    &.custom-select{
        @extend .form-control;
    }
}

.custom-file{
    .custom-file-label{
        @include focus();
    }
    .custom-file-input{
        @extend .custom-file-label;
    }
}

//inputs no editables
.no-event{
    cursor: not-allowed;
    input,
    .form-control{
        pointer-events: none;
        border: 0;
    }
}

//form-row con borde
.form-row{
    &.with-border{
        padding: 0.875rem;
        margin-bottom: 0.875rem;
        border: $border-width $border-style $border-filter-color;
        border-radius: $border-radius;
    }
}

//DateIntervalInputField
.DateIntervalInputField{
    .control-label {
        width: 100%;
    }
    .input-group{
        width: 100%;
        margin-bottom: 1rem;
        //media queries
        @include mq('tablet-medium'){
            width: 50%;
            float:left;
            margin-bottom: 0;
        }
    }

    > :nth-child(2)::after{
        content: "-";
        line-height: 2.5;
        padding: 0 3px;
        color: #9ca2a8;
    }
}
//dentro de modal
.into{
    &.DateIntervalInputField{
        .input-group{
            //media queries
            @include mq('tablet-medium'){
                width: auto;
            }
        }
    }
}

//icono en inputs
.input-group-prepend{
    .input-group-text{
        background-color: $bg-white;
    }
}

//mensajes de ayuda y error bajo inputs
small{
    &.help-block{
        min-height: $height-message;
        display: block;
        padding-top: 2px;
        font-family: $font-base;
        font-size: 0.7rem;
        font-weight: 400;
        color: #000;
    }
    &.js-error{
        @extend .help-block;
        color: $font-color-red;
    }
}

//dentro de tabla
table{
    small{
        &.help-block{
            min-height: 0;
            padding: 0;
            margin: 0;
        }
        &.js-error{
            @extend .help-block;
        }
    }
}

//anchura maxima de input o select, cuando no quiere que se ocupe el ancho de col
.max-width-input{
    max-width: 500px;
}

//mensaje bajo input - js-info -
.js-info{
    //min-height: 15px;
    font-family: $font-base;
    font-size: 0.75rem;
    line-height: $line-height;
}

//calendario e input dentro de tabla - hacemos que no haga wrap -
.table, .fix-form{
    .form-group{
        &.DateInputField{
            min-width: 136px;
            label{
                &.control-label{
                    @include hide();
                }
            }
            .input-group{
                @include hide();
                + .react-datepicker-wrapper{
                    position: relative;
                    &::before{
                        @include hide();
                        font-family: "Font Awesome 5 Free";
                        content: '\f073';
                        font-weight: 900;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: 9;
                        padding: 0.375rem 0.75rem;
                        border-right: 1px solid $border-filter-color;
                        pointer-events: none;
                        //media queries
                        @include mq('desktop'){
                            @include show();
                        }
                    }
                    input.form-control{
                        //media queries
                        @include mq('desktop'){
                            text-indent: 1.8rem;
                        }
                    }
                }
            }
        }
    }
}

//cabecera icono + titulo

.card-title .fa,
.card-title .fas{
    color:#B8B8B8;
    font-weight: 400;
    margin-right:14px;
}