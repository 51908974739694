//ÍTEMS ARRASTRAR

//parte a la izquierda de drag hours
.left-drag-hours {
  width: calc(100% - 90px);
}

//bloque arrastrar
.drag-hours {
  width: auto;
  // max-width: $drag-width;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background-color: $color-prevent;
  outline: $border-width $border-style $bg-white;
  font-size: $font-size;

  &.drag-absolute {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
  }
}

.drag-hours-support {
  background: repeating-linear-gradient(
                  -45deg,
                  $color-blue-dark 1px,
                  $color-drag-item 7px);
}

.drag-hours__item {
  width: 100%;
  // max-width: $drag-width;
  height: 100%;
  margin: 0;
  background-color: inherit;
  font-size: inherit;

  .drag-hours__item__content {
    width: 100%;
    //height: $drag-height;
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
  }

  .num-hours {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    font-family: $font-base;
    font-size: 0.750em; //12px
    font-weight: 800;
    line-height: 100%;
    color: $font-color-dark;

    .small {
      position: relative;
      top: 1px;
      display: inline-block;
      margin-left: 1px;
      font-size: 0.7em;
    }
  }

  .bar-hours {
    height: $drag-height;
    margin-left: 2px;
    background-color: $color-drag-item;
    //dentro de tabla
    table & {
      margin-left: auto;
      margin-right: auto;
    }
  }
}
.color-prevent{
  background-color: $color-prevent;
}
.color-program {
  background-color: $color-program;
}
.color-corrective{
  background-color: $color-corrective;
}
.color-events{
  background-color: $color-events;
}
//rayado
.color-prevent--lines{
  background: repeating-linear-gradient(
                  -45deg,
                  $bg-white,
                  $bg-white 3px,
                  $color-prevent 3px,
                  $color-prevent 6px
  );
}
.color-program--lines{
  background: repeating-linear-gradient(
                  -45deg,
                  $bg-white,
                  $bg-white 3px,
                  $color-program 3px,
                  $color-program 6px
  );
}
.color-corrective--lines{
  background: repeating-linear-gradient(
                  -45deg,
                  $bg-white,
                  $bg-white 3px,
                  $color-corrective 3px,
                  $color-corrective 6px
  );
}
.color-events--lines{
  background: repeating-linear-gradient(
                  -45deg,
                  $bg-white,
                  $bg-white 3px,
                  $color-events 3px,
                  $color-events 6px
  );
}
.is-user-fixed{
  border-top: #343a40 1px solid;
  border-bottom: #343a40 1px solid;
}
.is-date-fixed{
  border-right: #343a40 1px solid;
  border-left: #343a40 1px solid;
}

.drag-hours2 {
  @extend .drag-hours;
}

//position relative para que drag-absolute coja respecto a este elemento
table {
  .drop-hours {
    position: relative;
  }

  .th-name-tecnico {
    width: 110px;
    height: 33px;
    background-color: white;
    z-index: 59;
    position: absolute;
  }

  .th-tecnico {
    width: 110px!important;
  }


}
